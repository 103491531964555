import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import PhysiotherapyBanner from "../../assets/about_banner.jpg";
import Img1 from "../../assets/doctors/manisha_mishra.png";
import Img2 from "../../assets/doctors/satya_prakash_mohanty.png";
import Img3 from "../../assets/doctors/Dr.Dibyajit-Sabath.png";
import Img4 from "../../assets/doctors/user.png";
import Img5 from "../../assets/doctors/Dr.Sunita-Dutta.png";
import Img6 from "../../assets/department photos/physio-therapy-1.jpg";
import Img7 from "../../assets/department photos/physio-therapy-2.jpg";
import Img8 from "../../assets/department photos/physio-therapy-3.jpg";

function Physiotherapy() {
  const [currentImage, setCurrentImage] = useState("");
  const [currentCaption, setCurrentCaption] = useState("");
  const openModal = (image, caption) => {
    setCurrentImage(image);
    setCurrentCaption(caption);
    const modalElement = document.getElementById("exampleModal");
    const modal = new window.bootstrap.Modal(modalElement);
    modal.show();
  };
  const gallery = [
    { image: Img6, caption: "Dr. Manisha helping patient to walk" },
    { image: Img7, caption: "The hand exercise" },
    {
      image: Img8,
      caption:
        "Dr. Satya Prakash Mohanty helping patient to walk for recovery.",
    },
  ];
  const slides = [
    {
      image: Img1,
      name: "Dr. Manisha Mishra",
      qualification: "Physiotherapist",
      location: "Ashwini Hospital",
    },
    {
      image: Img2,
      name: "Dr. Satya Prakash Mohanty",
      qualification: "Masters of Physiotherapy",
      location: "Ashwini Hospital",
    },
    {
      image: Img3,
      name: "Dr. Dibyajit Sabath",
      qualification: "Bachelor of Physiotherapy",
      location: "Ashwini Hospital",
    },
    {
      image: Img4,
      name: "Dr. Bipin Swain",
      qualification: "Bachelor of Physiotherapy",
      location: "Ashwini Hospital",
    },
    {
      image: Img5,
      name: "Dr. Sunita Dutta",
      qualification: "Speech Therapist",
      location: "Ashwini Hospital",
    },
  ];

  return (
    <>
      <Navbar />

      {/* Physiotherapy Banner */}
      <section className="page-title text-center">
        <div className="bg-layer">
          <img
            src={PhysiotherapyBanner}
            alt="Physiotherapy Banner"
            title="Physiotherapy Banner"
          />
        </div>
        <div className="container">
          <div className="content-box">
            <ul className="bread-crumb clearfix">
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>
                <Link to={"/Departments"}>Departments</Link>
              </li>
              <li>Physiotherapy</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Physiotherapy Doctors */}
      <div className="my-3">
        <div className="container">
          <div className="sec-title">
            <h1>Our Esteemed Consultants</h1>
          </div>

          <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
            {slides.map((slide, index) => (
              <div className="col-lg-3 col-md-4" key={index}>
                <a
                  className="doc-name"
                  href={`/Doctors/${slide.name
                    .replace(/\s+/g, "")
                    .toLowerCase()}`}
                >
                  <div className="card h-100 doctor_img">
                    <img
                      src={slide.image}
                      alt={`${slide.name} Img`}
                      title={slide.name}
                    />
                    <div className="doc-text">
                      <h3>{slide.name}</h3>
                      <p>{slide.qualification}</p>
                      <span className="text-dark">{slide.location}</span>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Section */}
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
            {/* gallery photos */}
            <div className="card">
              <div className="card-body">
                <h2 className="card-title widget-title mb-3">Media</h2>
                <div className="widget-content clearfix">
                  <ul className="image-list">
                    {gallery.map((g, index) => (
                      <li key={index}>
                        <figure>
                          <img
                            src={g.image}
                            alt={g.caption}
                            title={g.caption}
                            onClick={() => openModal(g.image, g.caption)}
                          />
                        </figure>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Image Preview
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <p>{currentCaption}</p>
                  <img
                    src={currentImage}
                    alt={currentCaption}
                    title={currentCaption}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
            <div className="card">
              <div className="card about-style-two py-0">
                <div className="container card-body">
                  <div className="card-text">
                    <p>
                      Welcome to the Physiotherapy Department at Ashwini Group
                      of Hospitals, where we are dedicated to helping patients
                      restore movement, reduce pain, and improve their quality
                      of life through evidence-based physiotherapy
                      interventions. Our team of skilled physiotherapists
                      utilizes advanced techniques and state-of-the-art
                      facilities to provide personalized care for patients of
                      all ages and conditions.
                    </p>
                  </div>

                  {/* Scope of Services */}
                  <div className="card about-style-two py-0">
                    <div className="container card-body">
                      <div className="sec-title">
                        <h1>Scope of Services</h1>
                      </div>
                      <div className="card-text">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Neuro Rehabilitation Services</th>
                              <th scope="col">
                                Pulmonary Rehabilitation Services
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Pain Management</td>
                              <td>Breathing Exercises</td>
                            </tr>
                            <tr>
                              <td>Tilt Table Mobilisation</td>
                              <td>Abdominal Muscle Exercises</td>
                            </tr>
                            <tr>
                              <td>Chest Physiotherapy</td>
                              <td>Range of Motion Exercises</td>
                            </tr>
                            <tr>
                              <td>Postural Drainage</td>
                              <td>Postural Drainage</td>
                            </tr>
                            <tr>
                              <td>Sensory Re-education</td>
                              <td>Chest Muscles Strengthening</td>
                            </tr>
                            <tr>
                              <td>Cognitive Therapy</td>
                              <td>Chest Physiotherapy</td>
                            </tr>
                            <tr>
                              <td>Mat Exercises</td>
                              <td>Relaxation Techniques</td>
                            </tr>
                            <tr>
                              <td>Nutrition</td>
                              <td>Spirometer</td>
                            </tr>
                            <tr>
                              <td>Hand Rehabilitation</td>
                              <td>Resistance Training</td>
                            </tr>
                            <tr>
                              <td>Functional Electrical Stimulation</td>
                              <td>Aerobic Training</td>
                            </tr>
                            <tr>
                              <td>Transcutaneous Electrical Stimulation</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Balance & Coordination Training</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Ultrasound Therapy</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Robotic Glove</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Treadmill Training</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Gait Training</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Balance Training</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Exercise Therapy</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Speech Therapy</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Strength Training</td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Physiotherapy;
