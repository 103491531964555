import React, { useState } from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";
import SEO from "../../SEO/SEO";
import G1 from "../../../assets/department photos/neuro7.png";
import G2 from "../../../assets/department photos/neurosurgery1.png";
import G3 from "../../../assets/department photos/neuro3.png";
import G4 from "../../../assets/department photos/physio-therapy-2.jpg";
import G5 from "../../../assets/department photos/ICU-Care.jpg";

function AAHNeurosurgery() {
  const slides = [
    // {
    // }
  ];

  const gallery = [{ image: G1 }, { image: G2 }, { image: G3 }];

  const [currentImage, setCurrentImage] = useState("");
  const [currentCaption, setCurrentCaption] = useState("");

  const openModal = (image, caption) => {
    setCurrentImage(image);
    setCurrentCaption(caption);
    const modalElement = document.getElementById("exampleModal");
    const modal = new window.bootstrap.Modal(modalElement);
    modal.show();
  };

  return (
    <>
      <NavbarLocation />
      <SEO
        title="Top Neurosurgery Hospital in Odisha | Advanced Brain & Spine Surgery at Aditya Ashwini Hospital"
        description="Aditya Ashwini Hospital’s Neurosurgery Department in Odisha offers advanced treatment for brain and spine conditions. Our expert neurosurgeons specialize in brain tumor surgery, AVM, aneurysm, and minimally invasive spine procedures."
        keywords="Aditya Ashwini Hospital Neurosurgery, Aditya Ashwini Hospital Cuttack, Neurosurgery Odisha, Brain Surgery Ashwini Hospital, Spine Surgery Ashwini Hospital, Top Neuro Hospital India, Neuro ICU Odisha, Brain Tumor Surgery Odisha, DrNB Neurosurgery Ashwini Hospital, Advanced Neurosurgical Center Odisha, Endoscopic Brain Surgery India, Skull Base Surgery Ashwini Hospital, AVM Surgery Odisha, Neurosurgical Hospital in Cuttack"
      />
      {/* Neurosurgery Doctors */}
      <div className="doctors-box">
        <div className="container">
          <div className="sec-title">
            <h1>Our Esteemed Consultants</h1>
          </div>

          <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
            {slides.map((slide, index) => (
              <div className="col-lg-3 col-md-4" key={index}>
                <a
                  className="doc-name"
                  href={`/Doctors/${slide.name
                    .replace(/\s+/g, "")
                    .toLowerCase()}`}
                >
                  <div className="card h-100 doctor_img">
                    <img
                      src={slide.image}
                      alt={`${slide.name} Img`}
                      title={slide.name}
                    />
                    <div className="doc-text">
                      <h3>{slide.name}</h3>
                      <p>{slide.qualification}</p>
                      <span className="text-dark">{slide.location}</span>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Section */}
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12 mb-3 hero-section">
            {/* gallery */}
            <div className="card">
              <div className="card-body">
                <h2 className="card-title widget-title mb-3">Media</h2>
                <div className="widget-content clearfix">
                  <ul className="image-list">
                    {gallery.map((g, index) => (
                      <li key={index}>
                        <figure>
                          <img
                            src={g.image}
                            alt="Neuro Surgery Gallery"
                            title="Neuro Surgery Department"
                            onClick={() => openModal(g.image)}
                          />
                        </figure>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* Modal */}
            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Image Preview
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p>{currentCaption}</p>
                    <img
                      src={currentImage}
                      alt={currentCaption}
                      title={currentCaption}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
            <div className="card">
              <div className="card about-style-two py-0">
                <div className="container card-body">
                  <h3 className="sec-title">
                    Department of Neurosurgery at Ashwini Hospital, Odisha
                  </h3>
                  <h5 className="fs-6 fw-bold text-primary">
                    About the Department & Expertise
                  </h5>
                  <p>
                    At <strong>Aditya Ashwini Hospital, Odisha</strong>, our{" "}
                    <strong>Neurosurgery Department</strong> is recognized as
                    one of the most advanced centers in India for managing
                    complex neurological, spinal, and cerebrovascular
                    conditions. Equipped with state-of-the-art neurosurgical
                    suites, intraoperative imaging systems, and neuro-navigation
                    technology, our department ensures precision-driven surgical
                    care.
                  </p>
                  <p>
                    Our <strong>Highly Experienced Neurosurgeons</strong>,
                    supported by a multidisciplinary team of neurologists,
                    neuroradiologists, anesthetists, and specialized ICU staff,
                    offer personalized care through every stage of the patient
                    journey — from diagnosis to rehabilitation.
                  </p>
                </div>
              </div>

              {/* Neurosurgery Highlights */}
              <div className="container card-body">
                <h3 className="sec-title">
                  Neurosurgical Specialties at Ashwini Hospital
                </h3>
                <p>
                  Ashwini’s Neurosurgery Department covers a broad range of
                  high-end neurological procedures, delivered with accuracy,
                  compassion, and speed.
                </p>
                <h5 className="fs-6 fw-bold text-primary">
                  Key Neurosurgical Procedures Offered
                </h5>
                <ul className="custom-style">
                  <li>
                    <strong>Head and Spine Injury Management</strong> –
                    Emergency and trauma-based interventions.
                  </li>

                  <li>
                    <strong>Brain Tumor Surgery </strong>– Precision tumor
                    removal using microsurgical and endoscopic techniques.
                  </li>

                  <li>
                    <strong>Skull Base Surgery</strong> – Complex procedures
                    involving the base of the skull with minimal access.
                  </li>

                  <li>
                    <strong>AVM (Arteriovenous Malformation) Surgery</strong> –
                    Treatment of abnormal tangles of blood vessels in the brain.
                  </li>

                  <li>
                    <strong>
                      Surgery for Cerebrovascular Accidents (Stroke)
                    </strong>{" "}
                    – Clot removal and vascular restoration.
                  </li>
                  <li>
                    <strong>Aneurysm Surgery</strong> – Clipping or coiling of
                    brain aneurysms using advanced techniques.
                  </li>
                  <li>
                    <strong>Spinal Tumor Removal</strong> – Resection of
                    intradural and extradural spinal masses.
                  </li>
                  <li>
                    <strong>Endoscopic Neurosurgery </strong>– Minimally
                    invasive brain and skull base operations.
                  </li>
                  <li>
                    <strong>Degenerative Spinal Disease Surgery</strong> –
                    Procedures for disc prolapse, stenosis, and spinal fusion.
                  </li>
                </ul>

                <h5 className="fs-6 fw-bold text-primary">
                  Patient Care & Support Services
                </h5>
                <p>
                  At Ashwini, we emphasize not only excellent surgical outcomes
                  but also a holistic recovery process:
                </p>
                <ul className="list-unstyled">
                  <li>
                    &#9989;<strong>24/7 Neuro ICU</strong> care by intensivists
                    and neuro-specialists.
                  </li>

                  <li>
                    &#9989;Pre-operative education & consent for informed
                    decision-making.
                  </li>

                  <li>
                    &#9989;<strong>Rehabilitation & Physiotherapy</strong>{" "}
                    post-surgery for functional recovery.
                  </li>

                  <li>
                    &#9989;Regular family updates and{" "}
                    <strong>Post-Discharge Counseling Sessions</strong>.
                  </li>

                  <li>
                    &#9989;Continuity of care through <strong>Follow-up</strong>
                    consultations and telemedicine services.
                  </li>
                </ul>
                <div className="row text-center">
                  {[
                    { image: G4, caption: "Physiotherapy" },
                    { image: G5, caption: "ICU Care" },
                    // { image: "", caption: "Clinical Round" },
                  ].map((facility, index) => (
                    <div key={index} className="col-md-4 mb-4">
                      <div className="card h-100 shadow-sm">
                        <img
                          src={facility.image}
                          alt={facility.caption}
                          title={facility.caption}
                          className="img-fluid"
                          style={{ height: "200px", objectFit: "fill" }}
                          onClick={() =>
                            openModal(facility.image, facility.caption)
                          }
                        />
                        <div
                          className="p-2"
                          style={{ backgroundColor: "aliceblue" }}
                        >
                          <h5 className="fs-6 text-primary">
                            {facility.caption}
                          </h5>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AAHNeurosurgery;
