import React, { useState } from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";
import SEO from "../../SEO/SEO";
import Img1 from "../../../assets/doctors/subrat_jena.png";
import Img2 from "../../../assets/doctors/ruchi_gupta.png";
import Img3 from "../../../assets/doctors/user.png";
import G1 from "../../../assets/department photos/plasticsurgery11.jpg";
import G2 from "../../../assets/department photos/plasticsurgery12.jpg";
import G3 from "../../../assets/department photos/plasticsurgery13.jpg";
import G4 from "../../../assets/department photos/plasticsurgery14.jpg";
import G5 from "../../../assets/department photos/plasticsurgery15.jpg";
import G6 from "../../../assets/department photos/plasticsurgery16.jpg";
import G7 from "../../../assets/department photos/plasticsurgery17.jpg";
import G8 from "../../../assets/department photos/plasticsurgery8.png";
import G9 from "../../../assets/department photos/plasticsurgery9.png";
import G10 from "../../../assets/department photos/plasticsurgery10.png";
import G11 from "../../../assets/department photos/plasticsurgery1.png";
import G12 from "../../../assets/department photos/plasticsurgery2.png";
import G13 from "../../../assets/department photos/plasticsurgery3.png";
import G14 from "../../../assets/department photos/plasticsurgery4.png";
import G15 from "../../../assets/department photos/plasticsurgery5.png";
import G16 from "../../../assets/department photos/plasticsurgery6.png";
import G17 from "../../../assets/department photos/plasticsurgery7.png";
import G18 from "../../../assets/department photos/plasticsurgery18.jpg";
import G19 from "../../../assets/department photos/plasticsurgery19.jpg";
import G20 from "../../../assets/department photos/plasticsurgery20.jpg";
import G21 from "../../../assets/department photos/plasticsurgery21.jpg";
import G22 from "../../../assets/department photos/plasticsurgery22.jpg";
import G23 from "../../../assets/department photos/plasticsurgery23.jpg";
import G24 from "../../../assets/department photos/plasticsurgery24.jpg";
import G25 from "../../../assets/department photos/plasticsurgery25.jpg";
import G26 from "../../../assets/department photos/plasticsurgery26.jpg";
import G27 from "../../../assets/department photos/plasticsurgery27.jpg";
import G28 from "../../../assets/department photos/plasticsurgery28.jpg";

function ATCPlasticSurgery() {
  const [openIndex, setOpenIndex] = useState(null);
  const [currentImage, setCurrentImage] = useState("");
  const [currentCaption, setCurrentCaption] = useState("");
  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle accordion open/close
  };

  const openModal = (image, caption) => {
    setCurrentCaption(caption);
    setCurrentImage(image);
    const modalElement = document.getElementById("exampleModal");
    const modal = new window.bootstrap.Modal(modalElement);
    modal.show();
  };
  const slides = [
    {
      image: Img1,
      name: "Dr. Subrat Kumar Jena",
      qualification: "M.S(Ortho), M.Ch(Plastic Surgery)",
      location: "Ashwini Trauma Centre",
    },
    {
      image: Img2,
      name: "Dr. Ruchi Gupta",
      qualification: "M.S(General Surgeon), M.Ch(Plastic Surgery)",
      location: "Ashwini Trauma Centre",
    },
    {
      image: Img3,
      name: "Dr. Sudhakar Jena",
      qualification: "M.Ch(Plastic Surgery)",
      location: "Ashwini Trauma Centre",
    },
  ];

  const gallery = [
    { image: G28, caption: "Group of Factory Accident Victims" },
    { image: G27, caption: "Group got Reconstructive Surgery" },
    { image: G26, caption: "Replantation and Revascularisation Surgery" },
    { image: G25, caption: "Replantation and Revascularisation Surgery" },
    { image: G24, caption: "Dr. Subrat Kumar Jena on Clincal Round." },
    {
      image: G23,
      caption: "Dr. Subrat Kumar Jena meeting with a happy patient",
    },
    { image: G22, caption: "Dr. Subrat Kumar Jena on Clinical Visit" },
    { image: G21, caption: "Dr. Subrat Kumar Jena on Ward Visit" },
    { image: G20, caption: "Dr. Subrat Kumar Jena OPD Visit" },
    { image: G19, caption: "Discussing about the patient report" },
    {
      image: G18,
      caption:
        "Dr.Subrat Kumar Jena Discussing about the patient report with Team",
    },
    { image: G1, caption: "The Department Of Plastic Surgery" },
    {
      image: G2,
      caption:
        "Dr. Subrat Kumar Jena Preparing the Cut off hand of Reconstruction Surgery",
    },
    { image: G3, caption: "Reconstructive Burn Surgery" },
    { image: G4, caption: "" },
    { image: G5, caption: "" },
    { image: G6, caption: "" },
    { image: G7, caption: "" },
    { image: G8, caption: "" },
    { image: G9, caption: "" },
    { image: G10, caption: "" },
    { image: G11, caption: "" },
    { image: G12, caption: "" },
    { image: G13, caption: "" },
    { image: G14, caption: "" },
    { image: G15, caption: "" },
    { image: G16, caption: "" },
    { image: G17, caption: "" },
  ];

  const opdTimings = [
    {
      doctor: "Dr. Subrat Kumar Jena",
      schedule: [
        {
          day: "MON",
          time: "10:00 AM - 02:00 PM",
          centre: "Ashwini Trauma Centre",
        },
        {
          day: "WED",
          time: "10:00 AM - 02:00 PM",
          centre: "Ashwini Trauma Centre",
        },
        {
          day: "FRI",
          time: "10:00 AM - 02:00 PM",
          centre: "Ashwini Trauma Centre",
        },
      ],
    },
    {
      doctor: "Dr. Ruchi Gupta",
      schedule: [
        {
          day: "MON",
          time: "10:00 AM - 02:00 PM",
          centre: "Ashwini Trauma Centre",
        },
        {
          day: "WED",
          time: "10:00 AM - 02:00 PM",
          centre: "Ashwini Trauma Centre",
        },
        {
          day: "FRI",
          time: "10:00 AM - 02:00 PM",
          centre: "Ashwini Trauma Centre",
        },
      ],
    },
    {
      doctor: "Dr. Sudhakar Jena",
      schedule: [
        {
          day: "MON",
          time: "10:00 AM - 02:00 PM",
          centre: "Ashwini Trauma Centre",
        },
        {
          day: "WED",
          time: "10:00 AM - 02:00 PM",
          centre: "Ashwini Trauma Centre",
        },
        {
          day: "FRI",
          time: "10:00 AM - 02:00 PM",
          centre: "Ashwini Trauma Centre",
        },
      ],
    },
  ];
  return (
    <>
      <NavbarLocation />
      <SEO
        title="Best Plastic Surgery Hospital in Cuttack,Odisha | Reconstructive & Burn Care at Ashwini Trauma Centre"
        description="Ashwini Hospital’s Plastic Surgery Department in Odisha specializes in reconstructive surgery, aesthetic procedures, hand and microsurgery, and advanced burn management with dedicated ICU and isolation units."
        keywords="Ashwini Trauma Centre Plastic Surgery, Best Plastic Surgery Hospital Odisha, Reconstructive Surgery Ashwini Hospital, Aesthetic Surgery Odisha, Burn Surgery Ashwini Hospital, Hand and Microsurgery Odisha, Cosmetic Surgery Cuttack, Plastic Surgery ICU Odisha, Advanced Burn Management Odisha, Skin Grafting Ashwini Hospital, DrNB Plastic Surgery India, Top Plastic Surgery Hospital India, Craniofacial Surgery Odisha"
      />
      {/* Plastic Surgery Doctors */}
      <div className="doctors-box">
        <div className="container">
          <div className="sec-title">
            <h1>Our Esteemed Consultants</h1>
          </div>

          <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
            {slides.map((slide, index) => (
              <div className="col-lg-3 col-md-4" key={index}>
                <a
                  className="doc-name"
                  href={`/Doctors/${slide.name
                    .replace(/\s+/g, "")
                    .toLowerCase()}`}
                >
                  <div className="card h-100 doctor_img">
                    <img
                      src={slide.image}
                      alt={`${slide.name} Img`}
                      title={slide.name}
                    />
                    <div className="doc-text">
                      <h3>{slide.name}</h3>
                      <p>{slide.qualification}</p>
                      <span className="text-dark">{slide.location}</span>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Section */}
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12 mb-3 hero-section">
            {/* opd timings */}
            <div className="card mb-3">
              <div className="card-body">
                <h2 className="card-title widget-title mb-3">OPD Timings</h2>
                <div className="widget-content">
                  {opdTimings.map((opd, index) => (
                    <div className="accordion-item" key={`opd-${index}`}>
                      <h2 className="accordion-header" id={`heading${index}`}>
                        <button
                          className={`accordion-button ${
                            openIndex === index ? "" : "collapsed"
                          }`}
                          type="button"
                          onClick={() => toggleAccordion(index)}
                          aria-expanded={openIndex === index ? "true" : "false"}
                          aria-controls={`collapse${index}`}
                        >
                          {opd.doctor}
                          <span className="toggle-text">
                            {openIndex === index ? "Hide" : "Show"}
                          </span>
                        </button>
                      </h2>
                      <div
                        id={`collapse${index}`}
                        className={`accordion-collapse collapse ${
                          openIndex === index ? "show" : ""
                        }`}
                        aria-labelledby={`heading${index}`}
                        data-bs-parent="#opdTimingsAccordion"
                      >
                        <div className="accordion-body">
                          <table className="table table-hover table-light">
                            <thead>
                              <tr>
                                <th scope="col">DAYS</th>
                                <th scope="col">TIMINGS</th>
                                <th scope="col">CENTRE</th>
                              </tr>
                            </thead>
                            <tbody>
                              {opd.schedule.map((schedule, idx) => (
                                <tr
                                  className="opd-table"
                                  key={`schedule-${index}-${idx}`}
                                >
                                  <th scope="row">{schedule.day}</th>
                                  <td>{schedule.time}</td>
                                  <td>{schedule.centre}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* gallery photos */}
            <div className="card">
              <div className="card-body">
                <h2 className="card-title widget-title mb-3">Media</h2>
                <div className="widget-content clearfix">
                  <ul className="image-list">
                    {gallery.map((g, index) => (
                      <li key={index}>
                        <figure>
                          <img
                            src={g.image}
                            alt={g.caption}
                            title={g.caption}
                            onClick={() => openModal(g.image, g.caption)}
                          />
                        </figure>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* Modal */}
            <div
              className="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Image Preview
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p>{currentCaption}</p>
                    <img
                      src={currentImage}
                      alt={currentCaption}
                      title={currentCaption}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* about */}
          <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
            <div className="card">
              <div className="card about-style-two py-0">
                <div className="container card-body">
                  <div className="section-1">
                    <h3 className="sec-title">
                      Plastic Surgery Department at Ashwini Trauma Centre
                    </h3>
                    <h5 className="fs-6 fw-bold text-primary">
                      About the Department
                    </h5>
                    <p>
                      <strong>
                        Ashwini Trauma Centre’s Plastic Surgery Department
                      </strong>{" "}
                      is a recognized center of excellence in Odisha, offering a
                      comprehensive range of surgical services that restore,
                      reconstruct, and enhance physical appearance and function.
                      Our team of board-certified plastic surgeons is backed by
                      state-of-the-art infrastructure,{" "}
                      <strong>Dedicated ICUs</strong>, and world-class operation
                      theaters for highly specialized procedures. As a trusted
                      name in both Reconstructive and Aesthetic Plastic Surgery,
                      we also offer academic opportunities through DrNB Plastic
                      Surgery training programs.
                    </p>
                    <h4 className="sec-title">
                      Why Choose Ashwini Trauma Centre for Plastic Surgery in
                      Odisha?
                    </h4>
                    <ul className="custom-style">
                      <li>
                        <strong>NABH-Accredited</strong> hospital with expert
                        consultants
                      </li>

                      <li>Advanced surgical and post-operative care units</li>

                      <li>
                        Pioneers in reconstructive & burn care in Eastern India
                      </li>

                      <li>
                        Academic leadership in{" "}
                        <strong>DrNB Plastic Surgery</strong> programs
                      </li>

                      <li>
                        Personalized care tailored to every patient’s aesthetic
                        or medical need
                      </li>
                    </ul>
                    <h4 className="sec-title">Core Services</h4>
                    <ul className="list-unstyled ">
                      <li>
                        <strong className="text-primary">
                          Reconstructive Surgery
                        </strong>
                        &#9989;
                        <p>
                          We perform advanced procedures to repair and restore
                          function and appearance following trauma, birth
                          defects, infections, tumors, or diseases. Common
                          specialties include:
                        </p>
                        <ul>
                          <li>Craniofacial surgery</li>
                          <li>Hand surgery</li>
                          <li>Microsurgery</li>
                          <li>Scar revision</li>
                          <li>Post-tumor resection reconstruction</li>
                        </ul>
                      </li>
                      <li className="mt-2">
                        <strong className="text-primary ">
                          Aesthetic (Cosmetic) Surgery
                        </strong>
                        &#9989;
                        <p>
                          Focused on enhancing appearance through refined
                          surgical techniques:
                        </p>
                        <ul>
                          <li>Rhinoplasty (Nose surgery)</li>
                          <li>Liposuction</li>
                          <li>Facelift</li>
                          <li>Tummy tuck</li>
                          <li>Breast augmentation/reduction</li>
                        </ul>
                      </li>
                      <li className="mt-2">
                        <strong className="text-primary">
                          Burn Management & Surgery
                        </strong>
                        &#9989;
                        <p>
                          <strong>Ashwini Trauma Centre</strong> houses a{" "}
                          <strong>
                            Dedicated Burns ICU, Isolation Facilities
                          </strong>
                          , and a <strong>Specialized Burns OT</strong> to
                          provide timely, sterile, and effective management for
                          acute and chronic burn injuries. We specialize in:
                        </p>
                        <ul>
                          <li>
                            Skin grafting (Autografts, Allografts, Xenografts)
                          </li>
                          <li>Reconstructive burn surgery</li>
                          <li>Complex wound coverage</li>
                          <li>Tissue engineering techniques</li>
                        </ul>
                      </li>
                    </ul>
                    <div className="row text-center">
                      {[
                        { image: G2, caption: "Reconstructive Surgery" },
                        { image: G3, caption: "Reconstructive Burn Surgery" },
                        { image: G8, caption: "Complex Wound Coverage" },
                      ].map((facility, index) => (
                        <div key={index} className="col-md-4 mb-4 mt-2">
                          <div className="card h-100 shadow-sm">
                            <img
                              src={facility.image}
                              alt={facility.caption}
                              title={facility.caption}
                              className="img-fluid"
                              style={{ height: "200px", objectFit: "fill" }}
                              onClick={() =>
                                openModal(facility.image, facility.caption)
                              }
                            />
                            <div
                              className="p-2"
                              style={{ backgroundColor: "aliceblue" }}
                            >
                              <h5 className="fs-6 text-primary">
                                {facility.caption}
                              </h5>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="container card-body">
                      <h4 className="sec-title">Advanced Infrastructure</h4>
                      <ul className="custom-style">
                        <li>Dedicated 25-bedded Plastic Surgery wing</li>
                        <li>
                          Separate OT with laminar airflow for sterile
                          procedures
                        </li>
                        <li>Burns ICU and isolation ward</li>
                        <li>High-end imaging (CT/MRI) for pre-op planning</li>
                        <li>
                          Multidisciplinary team: Anesthetists, Intensivists,
                          Physiotherapists, Nutritionists
                        </li>
                      </ul>
                      <h4 className="sec-title">Expertise & Training</h4>
                      <p>
                        Ashwini Trauma Centre is proud to offer DrNB Plastic
                        Surgery academic training, nurturing the next generation
                        of plastic surgeons with exposure to:
                      </p>
                      <ul className="custom-style">
                        <li>Complex reconstructive procedures</li>

                        <li>Advanced microsurgical techniques</li>

                        <li>Cosmetic enhancements</li>

                        <li>Burn management</li>
                      </ul>
                      <p>
                        We believe in holistic care—from pre-operative
                        counseling to post-operative rehabilitation. Our focus
                        is always on patient comfort, safety, and satisfaction,
                        ensuring natural-looking outcomes and minimal downtime.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ATCPlasticSurgery;
