import React, { useState, useEffect } from "react";
import '../Academic.css';
import Navbar from "../../Navbar/Navbar";

function DNB() {
    const [activeTab, setActiveTab] = useState('Courses');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navbar />

            {/* DNB Message */}
            <div className="card course-title">
                <div className="container card-body">
                    <div className="sec-title">
                        <h1>DNB Course Details</h1>
                    </div>
                    <div className="text-box">
                        <p>Accredited by the National Board of Examinations, we provide top-notch Post
                            Graduate
                            training since 2021. Boasting 400 beds across three hospitals, well-equipped
                            departments, and a
                            modern library, we ensure a high case load and up-to-date resources. Our
                            hostels offer
                            personalized AC rooms for comfortable living. Stipends are in accordance
                            with State Government
                            Guidelines. Elevate your medical career with ASHWINI - Where Excellence
                            Meets Education!</p>
                    </div>
                </div>
            </div>

            {/* DNB structure */}
            <div className="course-box mb-3">
                <div className="container">
                    <ul className="nav nav-tabs">
                        <li className="nav-item tab_option">
                            <button className={`nav-link ${activeTab === 'Courses' ? 'active' : ''}`} onClick={() => handleTabClick('Courses')}>Courses</button>
                        </li>
                        <li className="nav-item tab_option">
                            <button className={`nav-link ${activeTab === 'Fee Structure' ? 'active' : ''}`}
                                onClick={() => handleTabClick('Fee Structure')}>Fee Structure</button>
                        </li>
                        <li className="nav-item tab_option">
                            <button className={`nav-link ${activeTab === 'Stipend' ? 'active' : ''}`}
                                onClick={() => handleTabClick('Stipend')}>Stipend</button>
                        </li>
                        <li className="nav-item tab_option">
                            <button className={`nav-link ${activeTab === 'Required Documents' ? 'active' : ''}`}
                                onClick={() => handleTabClick('Required Documents')}>Required Documents</button>
                        </li>
                    </ul>

                    <div className="tab-content">
                        {activeTab === 'Courses' && (
                            <div>
                                <table className="table table-bordered">
                                    <thead className="table-light">
                                        <tr>
                                            <th scope="col">Site</th>
                                            <th scope="col">Speciality</th>
                                            <th scope="col">Course</th>
                                            <th scope="col">No. of seats</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Ashwini Hospital</td>
                                            <td>Respiratory Medicine</td>
                                            <td>Post MBBS <br /> Post Diploma</td>
                                            <td>1 seat <br /> 1 seat</td>
                                        </tr>
                                        <tr>
                                            <td>Ashwini Hospital</td>
                                            <td>Emergency Medicine</td>
                                            <td>Post MBBS</td>
                                            <td>2 seats</td>
                                        </tr>
                                        <tr>
                                            <td>Ashwini Trauma Centre</td>
                                            <td>Orthopedics</td>
                                            <td>Post MBBS <br /> Post Diploma</td>
                                            <td>1 seat <br /> 1 seat</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {activeTab === 'Fee Structure' && (
                            <div className="fees">
                                <ul>
                                    <li><span>1st year</span> : Rs 1,25,000/- (Per Year)</li>
                                    <li><span>2nd year</span> : Rs 1,25,000/- (Per Year)</li>
                                    <li><span>3rd year</span> : Rs 1,25,000/- (Per Year)</li>
                                </ul>
                            </div>
                        )}

                        {activeTab === 'Stipend' && (
                            <div className="stipend">
                                <ul>
                                    <li><span>1st year</span> : Rs. 67,000/-</li>
                                    <li><span>2nd year</span> : Rs. 70,000/-.</li>
                                    <li><span>3rd year</span> : Rs. 73,000/-</li>
                                </ul>
                            </div>
                        )}

                        {activeTab === 'Required Documents' && (
                            <div className="documents">
                                <ul>
                                    <li>DNB/DrNB Joining Report</li>
                                    <li>DNB/DrNB fee submission 1st year receipt</li>
                                    <li>MCC Provisional Allotment Letter</li>
                                    <li>NEET Score Card</li>
                                    <li>Provisional admission letter</li>
                                    <li>NEET Admit Card</li>
                                    <li>Candidate medical registration latest certificate</li>
                                    <li>Internship Certificate</li>
                                    <li>MBBS Provisional Certificate</li>
                                    <li>MBBS All Marksheets</li>
                                    <li>10th Pass Certificate</li>
                                    <li>10th Marksheets</li>
                                    <li>Post MBBS Certificate Marksheets</li>
                                    <li>Aadhar Card</li>
                                    <li>PAN Card</li>
                                    <li>Cancelled Cheque</li>
                                    <li>Relieved Order(if any)</li>
                                    <li>Transfer Certificate or College Leaving Certificate</li>
                                    <li>Character Certificate</li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DNB;