import Img1 from "../../assets/doctors/maya_gantayet.png";
import Img2 from "../../assets/doctors/Soumya_Pradhan.png";
import Img4 from "../../assets/doctors/kanakaya_reddy.png";
import Img5 from "../../assets/doctors/sourav_mondal.png";
import Img6 from "../../assets/doctors/sibani_das.jpg";
import Img7 from "../../assets/doctors/biswa_ranjan_jena.png";
import Img9 from "../../assets/doctors/taiyaba_ishrat.png";
import Img10 from "../../assets/doctors/ruchi_gupta.png";
import Img12 from "../../assets/doctors/satya_prakash_mohanty.png";
import Img13 from "../../assets/doctors/bithika_jana.png";
import Img14 from "../../assets/doctors/swaleha.png";
import Img16 from "../../assets/doctors/chinmaya_sahu.png";
import Img17 from "../../assets/doctors/abhinas_das.png";
import Img18 from "../../assets/doctors/Dr.Debabrata-Tadu.png";
import Img21 from "../../assets/doctors/neethu_nahak.png";
import Img22 from "../../assets/doctors/smrutilata_sahoo.png";
import Img23 from "../../assets/doctors/Binay_ku_Jaiswal.jpg";
import Img24 from "../../assets/doctors/nimisha_padhi.jpg";
import Img25 from "../../assets/doctors/Dr.Benudhar-Lenka.png";
import Img26 from "../../assets/doctors/Dr.Sunita-Dutta.png";
import Img27 from "../../assets/doctors/manisha_mishra.png";
import Img28 from "../../assets/doctors/rashmi_ranjan_satpathy.png";
import Img29 from "../../assets/doctors/sagar_roy.jpg";
import Img30 from "../../assets/doctors/monali_pattnaik.jpg";
import Img31 from "../../assets/doctors/sampat_dash.png";
import Img32 from "../../assets/doctors/Dr. Naba Kumar.png";
import Img33 from "../../assets/doctors/satya_mohanty.png";
import Img34 from "../../assets/doctors/soumyaraj_ghosh.png";
import Img35 from "../../assets/doctors/sandip_pradhan.png";
import Img36 from "../../assets/doctors/alok_kumar_mohapatra.png";
import Img39 from "../../assets/doctors/user.png";
import Img40 from "../../assets/doctors/geeta_mohanty.jpg";
import Img41 from "../../assets/doctors/narayan_behera.png";
import Img42 from "../../assets/doctors/prasanna_kumar_mishra.png";
import Img43 from "../../assets/doctors/samir_sahu.jpg";
import Img44 from "../../assets/doctors/samadrita_roy.png";
import Img45 from "../../assets/doctors/manoranjan.jpg";
import Img46 from "../../assets/doctors/subrat_jena.png";
const doctorsData = {
  "dr.mayagantayet": {
    name: "Dr. Maya Gantayet",
    qualification: "DM(Neurology), M.Sc(Neuro-intervention)",
    position:
      "Managing Director & Co-Founder of Ashwini Group of Hospital, HOD, Faculty DrNB - Neurology",
    department: "Ashwini Hospital",
    image: Img1,
    about: [
      "Dr. Maya Gantayet is a renowned Neurologist with a distinguished career spanning decades.",
      "She began her medical journey by completing her MBBS at SCB Medical College in 1977.",
      "Driven by her passion for Neurology, she pursued a DM in Neurology at the prestigious National Institute of Mental Health and Neurosciences (NIMHANS), Bangalore, graduating in 1984.",
      "To further refine her expertise, Dr.Gantayet completed advanced Neurovascular training in 2007 at Mahidol University, Thailand, Paris and France.",
      "She also earned an MSc in Neurovascular Medicine from Mahidol University and Paris-Sud University. These accomplishments solidified her reputation as a leading figure in Neurovascular Medicine.",
      "Dr. Maya Gantayet continued her medical practice with a focus on Neurological disorders, eventually establishing Ashwini Hospital, a multispecialty healthcare facility, where she has served as the Managing Director of the Ashwini Group of Hospitals since 2004.",
      "Under her leadership, the hospital has become a center of excellence for advanced Neurological care.",
    ],
    education: [
      "M.B.B.S from Utkal University in 1978.",
      "DM Neurology from NIMHANS in 1984.",
      "M.Sc. Neurointervention.",
    ],
    experience: [
      "Research Article: Siba Shankar Dalai, Maya Gantayet. Combined Endoscopic and Computed Tomography-guided Trans-nasal Embolization of Intramuscular Haemangioma of the medial rectus muscle: A rare case. International Journal of Otolaryngology and Head and Neck Surgery (2022), March, 8(3):276-279.",
      "Research Report: Mamta Singh, Maya Gantayet. Detection of Microhemorrhages in Cerebral Amyloid Angiopathy by susceptibility-weighted MR imaging—An observational study. International Journal of Contemporary Medical Research (2019), August, Volume-6, Issue-8:1-6.",
      "Case Report: Mamta Singh, Maya Gantayat. Acute pulmonary embolism in Protein S deficiency: Two rare case reports of young males presenting with breathlessness.Journal of Medical & Allied Sciences (2018), Volume 8(2):86-89.",
      "Review Article: Maya Gantayet. Multiple Hypodense lesions in CT scan of the brain: Approach and management. Association of Physicians of India Update (2002): 806.",
      "Research Article: Maya Gantayet, Michael Swash. Single fibre EMG in acute Guillain-Barré Syndrome. Journal of Neurology, Neurosurgery, and Psychiatry (1991), June.",
    ],
    memberships: [
      "American Academy of Neurology (AAN).",
      "Indian Academy of Neurology (IAN).",
      "Association of Neuroscientists of Eastern India (ANEI).",
      "Neurology Association of Odisha (NAO).",
    ],
    specialization: ["Neurointervention", "Stroke", "Epilepsy"],
  },
  "dr.soumyaranjanpradhan": {
    name: "Dr. Soumya Ranjan Pradhan",
    qualification: "M.B.B.S, MD(Medicine), DM(Neurology)",
    position: "Consultant",
    department: "Ashwini Hospital , Aditya Ashwini Hospital",
    image: Img2,
    about: [
      "Dr. Soumya Ranjan Pradhan is a dedicated and skilled Consultant Neurologist, holding an MD in Medicine and a DM in Neurology.",
      "He has extensive training in diagnosing and treating a wide range of Neurological Disorders, with experience in both Outpatient and Inpatient settings.",
      "Utilizing advanced Neuroimaging techniques, he specializes in Neuroimmunology, Stroke management and Epilepsy care.",
      "Dr. Pradhan is committed to delivering high-quality patient care and actively participates in professional development to advance the field of Neurology.",
    ],
    education: [
      "DM in Neurology, SCB Medical College, 2019-2022.",
      "MD in Medicine, VIMSAR, 2016-2019.",
      "M.B.B.S, Medical College Kolkata, 2009-2015.",
    ],
    experience: [
      "Consultant Neurologist at Ashwini Group of Hospitals [2022 - Present].",
      "Conduct comprehensive clinical assessments and diagnosis for patients with a range of Neurological disorders in both Outpatient and Inpatient settings.",
      "Utilize advanced Neuroimaging techniques, including Digital Subtraction Angiography (DSA), to enhance Diagnosis and Treatment planning.",
      "Develop and implement tailored treatment plans, with a focus on conditions such as Stroke and Epilepsy.",
    ],
    memberships: [
      "Member, Indian Academy of Neurology (IAN).",
      "Member, Neurological Association of Odisha (NAO).",
    ],
    specialization: ["Neuroimmunology", "Stroke", "Epilepsy"],
  },
  "dr.nimishapadhi": {
    name: "Dr. Nimisha Padhi",
    qualification: "M.B.B.S, DNB Anesthesia",
    position: "Consultant",
    department: "Ashwini Trauma Centre",
    image: Img24,
    about: [
      "Dr. Nimisha Padhi holds an M.B.B.S and a DNB in Anesthesia, specializing in Operation Theatre (OT) practices, particularly in Smile Train Surgeries.",
      "She is experienced in providing Anesthesia care for Cleft Lip and Palate Surgeries, ensuring patient safety and comfort during procedures.",
      "Her focus on these Surgeries highlights her commitment to improving the lives of children with Congenital Deformities.",
    ],
    education: ["DNB Anesthesia "],
    experience: [
      "Dr. Nimisha Padhi is a Junior Consultant in the Department of Anesthesiology at Ashwini Trauma Centre in Cuttack.",
    ],
    memberships: ["ISA"],
    specialization: ["O.T ", "Smile Train Surgeries"],
  },
  "dr.bkanakayareddy": {
    name: "Dr. B Kanakaya Reddy",
    qualification: "M.S(Ortho), D.N.B",
    position: "HOD, Senior Consultant - Orthopedics",
    department: "Ashwini Trauma Centre",
    image: Img4,
    about: [
      "Dr. B.K Reddy is a leading Orthopedic Surgeon renowned for managing the highest volume of Trauma cases at the premier private Trauma Center in the state.",
      "Extensive experience in handling Polytrauma patients and complex Injuries.",
      "He is highly skilled in treating a Broad Spectrum of Trauma cases, including complex Acetabular and Pelvic Fractures.",
      "Specializing in Limb Salvage and Reconstruction procedures, Dr. Reddy excels in restoring function and mobility to patients with mangled extremities.",
      "His expertise extends to hand and Microsurgery, where they employ precise techniques to restore function of hand .",
      "Dr. Reddy is skilled in Brachial Plexus Surgery, addressing severe Nerve injuries to improve Limb function.",
      "Dr. Reddy has a robust background in Pediatric Orthopedic Surgery, offering comprehensive care for children with all types of Congenital Deformities  and injuries.",
      "Dr. Reddy's dedication to Trauma care and innovation in Orthopedic procedures underscores their commitment to improving patient outcomes and advancing the field of Orthopedic Surgery.",
    ],
    education: ["M.S Ortho (Delhi University)", "D.N.B Ortho "],
    experience: [
      "Dr. B.K. Reddy is a leading Orthopedic Surgeon with 9 years of experience at Ashwini Hospital.",
    ],
    memberships: ["SICOT", "IOA", "OOA", "ISSH"],
    specialization: [
      "Polytrauma",
      "Hand and Micro Surgery.",
      "Peripheral Nerve Surgery. ",
      "Pelvis and Acetabulum Fracture.",
      "Pediatric Orthopedic Surgery.",
      "Complex and Neglected Trauma.",
    ],
  },
  "dr.souravmondal": {
    name: "Dr. Sourav Mondal",
    qualification:
      "MS Orthopedics, Fellowship In Robotic Joint Replacement And Sports Medicine ",
    position: "Consultant",
    department: "Aditya Trauma Centre",
    image: Img5,
    about: [
      "Dr. Sourav Mandal, MS in Orthopedics and Fellowship in Robotic Joint Replacement and Sports Medicine, is a dynamic Orthopedic Surgeon.",
      "He specializes in Joint Replacement, Sports Medicine, Trauma and Complex Reconstructive Surgeries, including Pelviacetabular Surgery.",
      "Committed to staying at the forefront of Medical advancements, Dr. Mandal is dedicated to utilizing the latest techniques and technologies in his field to deliver optimal patient care.",
    ],
    education: ["M.S Orthopedics "],
    experience: ["4.5 years of experince at Ashwini Group Of Hospitals."],
    memberships: [],
    specialization: ["Joint Replacement.", "Sports Medicine. ", "Trauma."],
  },
  "dr.benudharlenka": {
    name: "Dr. Benudhar Lenka",
    qualification: "MS(Surgery), MCh. Neuro-surgery",
    position: "HOD - Neurosurgery",
    department: "Ashwini Hospital",
    image: Img25,
    about: [
      "Ex Prof and HOD Neuro Surgery SCB Medical College and Hospital Cuttack, Odisha.",
    ],
    education: ["M.B.B.S", "MS(surgery)", "Mch. (Neuro-surgery)"],
    experience: [
      "National and International exposure, Practicing Neurosurgeon, Consultant Aswin Hospital Cuttack, Odisha.",
    ],
    memberships: ["Life Member NSI", "NSSI", "ANEI"],
    specialization: [
      "Paediatric Neuro-surgery",
      "Spine Surgery",
      "Brain Surgery",
    ],
  },
  "dr.debabratatadu": {
    name: "Dr. Debabrata Tadu",
    qualification: "MS(Genral Surgery), MCh(Neurosurgery)",
    position: "Consultant",
    department: "Ashwini Hospital",
    image: Img18,
    about: [
      "Dr. Debabrata Tadu, MS in General Surgery and MCh in Neurosurgery, is a skilled Neurosurgeon specializing in Skull base Surgery, Vascular Surgery and Epilepsy Surgery.",
      "Expertise in handling complex Neurological conditions, he is dedicated to providing advanced Surgical care, focusing on improving patient outcomes in these challenging areas of Neurosurgery.",
    ],
    education: ["M.B.B.S", "MS(General Surgery)", "Mch. (Neuro-Surgery)"],
    experience: [
      "MS in General Surgery and MCh in Neurosurgery, specializing in Skull base Surgery, Vascular Surgery, and Epilepsy Surgery, with 2 years of experience at Ashwini Hospital.",
    ],
    memberships: ["NSI", "IMA"],
    specialization: [
      "Skull base Surgery",
      "Vascular Surgery",
      "Epilepsy Surgery",
    ],
  },
  "dr.smrutilatasahoo": {
    name: "Dr. Smrutilata Sahoo",
    qualification: "MD Microbiology",
    position: "Infection Control Officer",
    department: "Ashwini Hospital",
    image: Img22,
    about: [
      "Dr. Smrutilata Sahoo holds an MD in Microbiology and specializes as a Consultant and Infection Control Officer.",
      "Her expertise includes Infection Control practices and Antimicrobial Stewardship Programs (AMSP)",
      "She is dedicated to implementing strategies that prevent and control Infections in Healthcare Settings, ensuring patient safety and adherence to best practices.",
    ],
    education: ["M.B.B.S", "MD"],
    experience: [
      "Served as Senior Resident in Microbiology at KIMS, Bhubaneswar (2017-2018).",
      "Worked as Assistant Professor in the Microbiology Department at KIMS (2018-2023).",
      "Promoted to Associate Professor in the same Department (2023-2024).",
      "Currently working as a Consultant and Infection Control Officer at Ashwini Hospital, focusing on Infection Control and Antimicrobial Stewardship (2024-Present).",
    ],
    memberships: ["Core Committee on NTEP, Ashwini hospital."],
    specialization: ["Infection Control", "AMSP"],
  },
  "dr.sibanidas": {
    name: "Dr. Sibani Das",
    qualification: "MDS ( Oral and Maxillofacial Surgery )",
    position: "Consultant",
    department: "Ashwini Trauma Centre",
    image: Img6,
    about: [
      "Dr. Shibani Das is a qualified Oral and Maxillofacial Surgeon dedicated to patient care and professional growth.",
      "She has a special interest in Cleft and Craniofacial Surgery, Maxillofacial Trauma, Reconstructive Surgery, Orthognathic Surgery and Temporomandibular Joint Surgery.",
      "With a focus on delivering exceptional care, Dr. Das combines her expertise with a passion for learning within a dynamic healthcare environment.",
    ],
    education: [
      "BDS ",
      "MDS (Oral and Maxillofacial Surgery) ",
      "Smile Train Accredited Cleft Surgeon",
    ],
    experience: [
      "Dr. Shibani Das has been working at Ashwini Trauma Centre since 2019 as a qualified Oral and Maxillofacial Surgeon.",
    ],
    memberships: [
      "Association of Oral and Maxillofacial Surgeons of India.",
      "International Association of Oral and Maxillofacial Surgeons.",
      "AO - Craniofacial Surgery, Switzerland.",
    ],
    specialization: [
      "Cleft Surgery ",
      "Maxillofacial Trauma",
      "Orthognathic Surgery ",
      "Temporomandibular Surgery ",
    ],
  },
  "dr.sunitadutta": {
    name: "Dr. Sunita Dutta",
    qualification: "D.S.E M.R (Specialization in Speech)",
    position: "Speech Therapist",
    department: "Ashwini Hospital",
    image: Img26,
    about: [
      "Sunita Dutta, having experience in Speech Therapy for 24 years.",
      "Presently working as a Speech Educator in Aswini Hospital, CDA, Cuttack.",
    ],
    education: ["D.S.E M.R (Specialization in Speech) "],
    experience: [
      "24 years as Speech Educator.",
      "Curently working at Ashwini Hospital, CDA, Cuttack.",
    ],
    memberships: [],
    specialization: ["Speech Educator"],
  },
  "dr.abhinasdas": {
    name: "Dr. Abhinas Das",
    qualification: "Masters in Physiotherapy Orthopedic",
    position: "Physiotherapist",
    department: "Ashwini Trauma Centre",
    image: Img17,
    about: [
      "Dr. Abinash Das is a seasoned Physiotherapist with over 12 years of experience, currently serving as the Head of Physiotherapy at the Odisha Cricket Association.",
      "He specializes in Orthopedic and Sports Injuries, where he utilizes his expertise to develop tailored Rehabilitation programs for athletes.",
      "Dr. Das employs evidence-based practices to ensure effective recovery and optimal performance.",
    ],
    education: ["Masters in Physiotherapy Orthopedic."],
    experience: [
      "Ashwini Trauma Centre: Head Physiotherapist for 5 years, focusing on Orthopedic Rehabilitation and Injury Management.",
      "Odisha Cricket Association: Head Physiotherapist for 9 years, Specializing in Sports Injury Rehabilitation..",
    ],
    memberships: ["IAP"],
    specialization: ["Orthopedic", "Sport's Injuries"],
  },
  "dr.neethusujalanahak": {
    name: "Dr. Neethu Sujala Nahak",
    qualification:
      "M.B.B.S, Masters in Emergency Medicine (SEMI), Fellowship in Advanced Emergency Medicine, MBA in Health Care Services",
    position: "Consultant",
    department: "Ashwini Hospital",
    image: Img21,
    about: [
      "Dr. Neethu Sujala Nahak is an Emergency Medicine Specialist with an M.B.B.S, Master's in Emergency Medicine (SEMI) and a Fellowship in Advanced Emergency Medicine from The Alfred Emergency & Trauma Centre, Melbourne.",
      "She specializes in Advanced Airway Procedures, Central/Peripheral Venous Catheterization, Point of Care Ultrasound, Trauma Management, Toxicology and Pediatric Resuscitation.",
      "With expertise in procedures like Tracheostomy, Lumbar Puncture and Thoracentesis, she also excels in managing patient flow and coordination post-stabilization.",
      "Additionally, she holds an MBA in Healthcare Services from IIM, Ahmedabad.",
    ],
    education: [
      "M.B.B.S",
      "Masters in Emergency Medicine (SEMI)",
      "Certificate of Advanced Emergency Medicine from The Alfred Emergency & Trauma Centre, Melbourne",
      "MBA in Health Care Services-IIM, Ahmedabad",
      "Certification of POCUS Ultrasound",
      "BLS",
      "ACLS",
      "ATLS",
      "PALS",
      "NALS",
    ],
    experience: [
      "2015: Duty Medical Officer at Karthikan Hospital, Velachery, Chennai.",
      "2016-2019: Junior Resident in Emergency Medicine at Care Hospitals, Visakhapatnam.",
      "2017-2018: Emergency Medical Officer (Freelancer) at Apollo Hospitals, Visakhapatnam.",
      "2019: Resident Medical Officer, Emergency Department at St. John’s Medical Institute, Bangalore.",
      "2019: Consultant at St. John's, Bangalore.",
      "2019-2021: Consultant at Konaseema Institute of Medical Sciences (KIMS), Amalapuram.",
      "2021-2023: Consultant at Seven Hills Hospital.",
      "2023-2024: Consultant at MedUnited.",
    ],
    memberships: [
      "Member at Society of Emergency Medicine India (AP-312).",
      "Life Member at Indian Medical Association AP/25690/32.",
    ],
    specialization: [
      "Advanced Airway Procedures",
      "Peripheral, Central Venous Catheterization",
      "Point of Care Ultrasound ",
      "EFast",
      "Emergency Radiology ",
      "Cardiopulmonary Resuscitation",
      "Management of patient flow & transfer Coordination post stabilization",
      "Toxicology case management ",
      "Disaster Management",
      "Paediatric Resuscitation",
      "Advanced Procedures - Tracheostomy, Lumbar",
      "Puncture, Paracentesis, Nasal packing in Epistaxis",
      "Reduction of Shoulder Joint Dislocation, Thoracentesis, I&D",
    ],
  },
  "dr.biswaranjanjena": {
    name: "Dr. Biswa Ranjan Jena",
    qualification: "MD, DM, FSCAI, FESC",
    position: "Cardiologist",
    department: "Ashwini Hospital",
    image: Img7,
    about: [
      "Dr. Biswaranjan Jena is a highly qualified Interventional Cardiologist with certifications including MD (Medicine), DM (Cardiology), FESC, FSCAI, and PDCC from CMC, Vellore.",
      "He specializes in complex Angioplasty, Pacemaker Implantation, Heart Failure Device Management and Peripheral Angioplasty.",
      "His expertise in handling intricate Cardiovascular Procedures makes him a prominent figure in the field of Interventional Cardiology.",
    ],
    education: [
      "MD(Medicine)",
      "DM(cardiology)",
      "FESC",
      "FSCAI",
      "PDCC(CMC,Vellore)",
    ],
    experience: [
      "Dr. Biswaranjan Jena is a Senior Consultant in Interventional Cardiology at Dr. Jena’s Cardio Diabetes Center and Ashwini Hospital, Cuttack.",
      "Sunshine Hospital, BBSR Senior Consultant, Interventional Cardiology 2022-2023.",
      "Ashwini Hospital, Cuttack & Aditya Ashwini Hospital, BBSR Director & Consultant, Interventional cardiology 2019-2022.",
      "Madras Medical Mission, Chennai Paediatric Intervention (Obsevership) 2018.",
      "Apollo Hospitals, Bhubaneswar Consultant Interventional Cardiology 2016 – 2017.",
      "Arneja Heart Institute, Nagpur Consultant Interventional Cardiology 2015 – 2016.",
      "Apollo Hospitals, Bhubaneswar Junior Consultant – Interventional Cardiology 2014 – 2015.",
      "Institute of Cardiovascular Sciences, S.C.B.Medical College, Cuttack PG Senior Resident (DM) 2011 – 2014.",
      "SLIMS Medical College, Pondicherry Assistant Prof, Medicine 2011 10. Christian Medical College, Vellore, India Research Officer in Diabetes 2010 – 2011.",
    ],
    memberships: ["FESC", "FSCAI"],
    specialization: [
      "Complex Angioplasty",
      "Pacemaker ",
      "Heart Failure Device ",
      "Peripheral ",
      "Angioplasty",
    ],
  },
  "dr.satyaprakashmohanty": {
    name: "Dr. Satya Prakash Mohanty",
    qualification: "B.P.T, M.P.T (Neurology)",
    position: "Physiotherapist",
    department: "Ashwini Hospital",
    image: Img12,
    about: [
      "Dr. Satya Prakash Mohanty is a Neuro Physiotherapist with an M.P.T. in Neurology and over three years of clinical experience.",
      "He specializes in the Rehabilitation of patients with conditions such as Brain Stroke, Parkinson's Disease, Chronic Low Back Pain, Cervical Radiculopathy, Delayed Milestones, Traumatic Brain Injury, COPD, and Bell’s Palsy.",
      "Passionate about helping individuals recover, he focuses on restoring strength and mobility while educating patients about their conditions.",
      "Dr. Mohanty is certified in Dry Needling, Cupping Therapy and Chiropractic Techniques (Level 1 and Level 2) from iTHRUST, Italy.",
    ],
    education: [
      "Certified Dry Needling Therapist, UK.",
      "Certified Chiropractor Level 1 and Level 2 from iTHRUST, Italy.",
      "Certified Cupping Therapist.",
    ],
    experience: [
      "Dr. Satya Prakash Mohanty is a Consultant Physiotherapist at UPHC Thoriasahi under the National Health Mission in Cuttack, where he has worked for 2 years",
      "Incharge and Physiotherapist at Ashwini Hospital.",
    ],
    memberships: ["Indian Association of Physiotherapy (IAP) "],
    specialization: [
      "Manual Therapy",
      "Brunnstorms Rehabilitation",
      "Cognitive Rehabilitation ",
      "Dry Needling Therapy",
      "McKenzie Back Pain Management ",
      "Neuro Developmental Technique",
      "Mulligan Mobilisation",
    ],
  },
  "dt.bithikajana": {
    name: "Dt. Bithika Jana",
    qualification: "M.Sc. Nutritional and Dietetics",
    position: "Dietitian",
    department: "Ashwini Hospital",
    image: Img13,
    about: [
      "Dr. Bithika Jana holds a Master's degree (M.Sc.) in Nutritional and Dietetics and works in the Dietetics Department.",
      "She specializes in Patient Counseling and provides personalized Diet Charts based on individual Diagnosis.",
      "Dr. Jana is committed to promoting health and wellness through tailored Nutrition plans, ensuring her patients receive the Dietary guidance they need to support their recovery and overall well-being.",
    ],
    education: [
      "Post-Graduation in Nutritional and Dietetics from Vidyasagar University.",
    ],
    experience: [
      "M.Sc. in Nutritional and Dietetics: Dr. Bithika Jana holds a Master’s degree in this field.",
      "2023-2024: Began her career at ILS Hospital, Agartala, Tripura.",
      "Present: Working at the Dietetics Department at Ashwini Hospital.",
      "Specialization: Focuses on patient counseling and creates personalized Diet Charts based on individual health Diagnosis.",
    ],
    memberships: [
      "Sahayata- Nutrition, Health, Education and Research Foundation",
    ],
    specialization: ["Nutritional", "Dietetics"],
  },
  "dr.manishamishra": {
    name: "Dr. Manisha Mishra",
    qualification: "B.P.T, M.P.T (Neurology)",
    position: "Physiotherapist",
    department: "Ashwini Hospital",
    image: Img27,
    about: [
      "Dr. Manisha Mishra is a qualified Neuro-Physiotherapist with a B.P.T and M.P.T (Neurology).",
      "As a Senior Consultant, she specializes in the Rehabilitation of patients with Neurological Disorders, including Stroke, Traumatic Brain Injuries, Spinal Cord Injuries, Multiple Sclerosis and Parkinson’s Disease.",
      "Dr. Mishra is passionate about improving patient's quality of life and staying updated with advancements in Neuro-Rehabilitation.",
      "She also has expertise in implementing Rehabilitation Programs to restore motor Function, Balance, and Mobility, as well as managing Low Back Pain through Physiotherapeutic Techniques and Exercises.",
    ],
    education: [
      "She completed her Bachelor’s in Physiotherapy (BPT) from Utkal University.",
      "Completed Master’s in Physiotherapy (Neurology) from Swami Rama Himalayan University in 2021.",
      "Certified in Chiropractic Level 1 and Level 2 from iTHRUST, Italy.",
      "Certified in Cupping Therapy.",
    ],
    experience: [
      "Consultant Physiotherapist at Ashwini Hospital (2022 – Present):- Providing Specialized Physiotherapy Services and managing patient Rehabilitation Programs.",
      "In-charge & Physiotherapist at Ashwini Hospital:-Responsibilities: Overseeing the operations and delivering Physiotherapy treatments to patients, focusing on recovery and mobility improvement.",
    ],
    memberships: ["Member of Indian Association of Physiotherapist (IAP)"],
    specialization: [
      "Neuro Physiotherapy: Specialized in the Rehabilitation of patients with Neurological Disorders such as Stroke, Traumatic Brain Injuries, Spinal Cord Injuries, Multiple Sclerosis and Parkinson’s Disease.",
      "Rehabilitation: Expertise in implementing comprehensive Rehabilitation Programs for restoring motor Function, Balance, and Mobility.",
      "Low Back Pain Management: Specialize in assessing and treating Low Back Pain through Physiotherapeutic Techniques, Spinal Adjustments and Exercises to reduce Pain, improve Mobility, and prevent Recurrence.",
    ],
  },
  "dt.swalehashirin": {
    name: "Dt. Swaleha Shirin",
    qualification: "Master's in Dietetics and Food Service Management ",
    position: "Dietitian",
    department: "Ashwini Trauma Centre",
    image: Img14,
    about: [
      "Dr. Swaleha Shirin is a Dietitian from Cuttack, Odisha with a Master's in Dietetics and Food Service Management.",
      "She holds certifications from the Institute of Nutrition and Fitness Sciences and has completed internships at Apollo and KIMS Hospitals.",
      "Specializing in Pediatric and Critical Care Nutrition, Swaleha is skilled in providing tailored Nutritional Support for children and patients with severe health conditions, focusing on promoting recovery and overall well-being.",
    ],
    education: [
      "Certification in foundation and Expert course from Institute of Nutrition and Fitness Sciences.",
      "Certificate course in Food and Nutrition from IGNOU.",
    ],
    experience: [
      "2 years: Worked as a Clinical Dietitian at Sadhguru Hospital, providing Dietary Care for patients with various medical conditions.",
      "4 months: Worked as a Dietitian at VLCC Bhubaneswar, focusing on Wellness and Weight Management.",
      "3 years: Currently Serving as a Clinical Dietitian at Ashwini Trauma Centre, specializing in managing the Nutritional needs of critically ill patients to optimize recovery and improve health outcomes.",
    ],
    memberships: [],
    specialization: ["Paediatric Nutrition", "Critical Care Nutrition "],
  },
  "dr.rashmiranjansatapathy": {
    name: "Dr. Rashmi Ranjan Satpathy",
    qualification: "M.B.B.S, DNB",
    position: "Senior Consultant",
    department: "Ashwini Hospital",
    image: Img28,
    about: [
      "Dr. Rashmi Ranjan Satapathy is a highly experienced Anesthesiologist with 15 years of expertise.",
      "He holds qualifications in M.B.B.S, DNB in Anesthesia and a Fellowship in Pain and Spine Intervention.",
      "Dr. Satapathy has developed a strong specialization in Regional Anesthesia and Pain Management, focusing on minimizing risks for patients during various procedures.",
      "His skills extend to managing difficult Airways and providing Anesthesia for high-risk patients, particularly in settings that emphasize patient safety and Advanced Pain Management Techniques.",
    ],
    education: [
      "M.B.B.S ",
      "DNB Anesthesia ",
      "Fellowship in Pain and Spine Intervention",
    ],
    experience: [
      "Senior Consultant & HOD – Department of Anesthesiology Ashwini Group of Hospitals, Cuttack From 2022 till date.",
      "Senior Consultant Anesthesia and Critical Care ( ICU Incharge) – ASHWINI HOSPITAL, Cuttack, Odisha From 2020–2021.",
      "Chief Consultant Anesthesiologist – SRIKARA HOSPITAL, Hyderabad From 2019–2020.",
      "HOD and Consultant Anesthesiologist – ASTER PRIME HOSPITAL, Hyderabad From 2017–2019.",
      "Consultant Anesthesiologist - SUNSHINE HOSPITALS, Bhubaneswar, Odisha From 2015–2017.",
      "Consultant Anesthesiologist - ASHWINI HOSPITAL, Cuttack, Odisha From 2011-2015.",
      "Anesthesiologist at APOLLO HOSPITALS, Bhubaneswar, Odisha From 2010- 2011.",
      "Anesthesiologist and Intensivist - MYTHRI multispeciality Hospital, Hyderabad From 2010-2010.",
      "Resident Anesthesiologist (DNB trainee) - GLOBAL HOSPITALS, Hyderabad, From 2006- 2010.",
    ],
    memberships: ["ISA"],
    specialization: [
      "Regional Anesthesia and Pain Management.",
      "Dificult Airway.",
      "Anesthesia for high risk patients.",
    ],
  },
  "dr.taiyabaishrat": {
    name: "Dr. Taiyaba Ishrat",
    qualification: "M.B.B.S, DGO, DNB, Fellowship Gynec Laparoscopy",
    position: "Gynaecologist",
    department: "Aditya Ashwini Hospital",
    image: Img9,
    about: [
      "Dr. Taiyaba Ishrat is a highly qualified Obstetrician and Gynecologist with an M.B.B.S, DGO, DNB, and Specialized  Fellowships in Gynec Laparoscopy and Cosmetic Laparoscopy Gynecology.",
      "She excels as a Laparoscopic Surgeon, Cosmetic Gynecologist and Fertility Specialist, offering advanced minimally Invasive and Aesthetic Treatments.",
      "Her expertise spans across Reproductive Health, Fertility Solutions and Cosmetic Gynecology, providing comprehensive, patient-centered care.",
    ],
    education: [
      "Fellowship Gynec Laparoscopy ",
      "Fellowship Cosmetic Laparoscopy Gynecology ",
    ],
    experience: [
      "Registrar at Rainbow Children Hospital and Birthright (2017) – Gained foundational experience in Obstetrics and Gynecology.",
      "Junior Consultant at Rainbow Children Hospital and Birthright (2018-2020) – Provided Advanced Patient Care in Gynecology.",
      "Fellowship in Gynec Laparoscopy at AMRI (2021) – Specialized in minimally Invasive Gynecological Surgery.",
      "Junior Consultant at AMRI (2021-2022) – Focused on Laparoscopic and Cosmetic Gynecology.",
      "Consultant at Aditya Ashwini Hospital (2022-Present) – Offers expertise in Gynecological Surgery and Fertility Treatments.",
    ],
    memberships: ["BOGS", "FOGSI"],
    specialization: [
      "High Risk Pregnency ",
      "Gynec Surgery ",
      "Fertility",
      "Cosmetic Gynic Surgery",
    ],
  },
  "dr.ruchigupta": {
    name: "Dr. Ruchi Gupta",
    qualification: "I.C.S.E, M.B.B.S, M.S, M.Ch",
    position: "Consultant",
    department: "Ashwini Trauma Centre",
    image: Img10,
    about: [
      "Dr. Ruchi Gupta is a highly qualified Specialist in Burn and Plastic Surgery.",
      "She completed her I.C.S.E and I.S.C at Ingraham Institute English School in Ghaziabad, followed by her M.B.B.S and M.S at the Institute of Medical Sciences, Banaras Hindu University.",
      "She earned her M.Ch degree in 2005 from N.H.L. Medical College, Gujarat University.",
      "Dr. Gupta is dedicated to providing high-quality care and utilizing Advanced Surgical techniques to improve patient outcomes in her field.",
    ],
    education: [
      "I.C.S.E Ingraham Institute English 1985 School, Ghaziabad",
      "I.S.C Ingraham Institute English 1987 School, Ghaziabad",
      "M.B.B.S Institute of Medical Science 1992 Banaras Hindu University",
      "M.S Institute of Medical Science 1996 Banaras Hindu University",
      "M.Ch N.H.L. Medical College 2005 Gujarat University",
    ],
    experience: [
      "Gained foundational skills in Surgery and Patient Management during her initial training as Junior Resident at Institute of Medical Sciences, Banaras Hindu University from 1994 - 1996.",
      "Focused on Advanced Surgical Techniques in Plastic and Reconstructive Surgery, managing complex cases as Senior Resident (Plastic Surgery) Sir Ganga Ram Hospital, New Delhi 1997.",
      "Educated and trained medical students in Surgical principles and practices, contributing to their clinical skills development as Lecturer at Santosh Medical & Dental College, Ghaziabad from 1997 - 1998.",
      "Further developed her expertise in Plastic Surgery, participating in various Reconstructive Procedures as Senior Resident (Plastic Surgery) at Himalayan Institute, Jolly Grant, Dehradun from 1998 – 1999 and get promoted as Assistant Professor (Plastic Surgery) from 2000 – 2001",
      "Specialized in managing Trauma cases and performing Intricate Reconstructive Surgeries as Senior Resident (Plastic Surgery) at L.G. Hospital, N.H.L. Medical College, Ahmedabad from 2001 - 2004.",
      "Provided comprehensive care for patients requiring Plastic and Reconstructive Surgery, emphasizing patient outcomes as Consultant Plastic Surgeon at Ashwini Hospitals, Cuttack from 2010 – 2015.",
      "Leads the Plastic Surgery Department, overseeing clinical operations and implementing best practices in Surgical Care as Director & Chief Consultant, Plastic Surgery at Shakti Hospital, Cuttack from 2012 - Present.",
      "Continues to deliver high-quality care and stay abreast of advancements in Plastic Surgery as Consultant at Ashwini Trauma Centre, Cuttack from 2022 – Present.",
    ],
    memberships: ["APSI", "Current Vice President of APRSO"],
    specialization: [],
  },
  "dr.chinmayasahu": {
    name: "Dr. Chinmaya Sahu",
    qualification: "M.B.B.S, MD.Anesthesia",
    position: "Consultant",
    department: "Ashwini Hospital",
    image: Img16,
    about: [
      "Dr. Chinmaya Sahu is a highly skilled Anesthesiologist and Critical Care Consultant, renowned for his dedication to patient-centered care and advanced management of critically ill patients.",
      "As a Consultant in Critical Care at Ashwini Hospital, Dr. Sahu brings a wealth of expertise to the ICU, focusing on delivering compassionate, high-quality care.",
      "His commitment to improving patient outcomes and advancing Critical Care standards is at the core of his practice, making him a respected leader in his field.",
    ],
    education: ["M.B.B.S", "MD.Anesthesia"],
    experience: [
      "MD in Anesthesia:-Completed in March 2012 at Popular Nursing Home, Cuttack.",
      "Anesthesiologist:-Served at Sun Hospital from 2020 to 2022, providing Anesthesia Care for various Surgical Procedures.",
      "Consultant in Critical Care (ICU):-Shanti Memorial Hospital:-Worked from 2015 to 2022, managing critically ill patients and overseeing ICU operations.",
      "Consultant in Critical Care (ICU):-Ashwini Hospital: Currently employed since 2023, continuing his role in Critical Care and enhancing Patient Management in the ICU setting.",
    ],
    memberships: [],
    specialization: [],
  },
  "dr.sagarroy": {
    name: "Dr. Sagar Roy",
    qualification: "M.B.B.S, MD FACEE",
    position: "Senior Consultant",
    department: "Ashwini Hospital",
    image: Img29,
    about: [
      "Dr. Sagar Roy is an accomplished Emergency Physician with an M.B.B.S from Agartala Government Medical College and an MD in Emergency Medicine, where he received a Gold Medal from M S Ramaiah Medical College, Bengaluru.",
      "He holds a Fellowship from the Academic College of Emergency Experts (FACEE) and specializes in Advanced Emergency and Trauma Care.",
      "Dr. Roy is trained in Advanced Airway Management, Trauma Care and Point-Of-Care Ultrasound and is certified in BLS and ACLS.",
      "He is committed to enhancing patient care and has published research in National and International Journals.",
    ],
    education: [
      "M.B.B.S from Agartala Government Medical College.",
      "MD Emergency Medicine with Gold medal from Premier Institute M S Ramaiah Medical College under Rajiv Gandhi Central Health University Bengaluru.",
      "Obtained Fellowship of the Academic College of the Emergency Experts(FACEE).",
      "Trained in Advanced Airway Management from Ramaiah Institute of Advanced Learning centre.",
      "Trained in Advanced Trauma Care under Alfred Trauma Care Australia.",
      "Trained in point of care Ultrasound from Sonoschool UAE organized in Ramaiah Medical College.",
      "Provider For BLS, ACLS, ATLS, PALS, NALS.",
      "Publications of papers at National & International Journals.",
    ],
    experience: [
      "Current Position - Senior Consultant in Emergency Medicine at Ashwini Group of Hospitals:- Oversees Emergency department management and staff training.",
      "Angel's Stroke Organization, Bengaluru:- Focused on early Stroke detection and community education.",
      "Heart Rescue India Project, Bengaluru: - Advanced Cardiac Resuscitation Training and Protocol Development.",
      "Senior Residency at CIMS, Karnataka: - Managed Emergency cases and supervised junior residents.",
      "Senior Consultant at Aster RV Hospital, Bengaluru: Led emergency response teams and improved clinical pathways.",
      "HOD & DNB Faculty at St. Philomena's Teaching Hospital, Bengaluru:- Trained DNB residents and conducted research in Emergency Medicine.",
    ],
    memberships: [
      "Indian Medical Association",
      "Emergency Medicine Association",
      "Society Of Emergency Medicine Of India",
      "Angels Stroke Organization",
    ],
    specialization: [
      "Emergency Medicine",
      "Critical Care Medicine",
      "Trauma Care",
    ],
  },
  "dr.binaykumarjaiswal": {
    name: "Dr. Binay Kumar Jaiswal",
    qualification: "DM Neurology, MD General Medicine, M.B.B.S",
    position: "Senior Consultant",
    department: "Ashwini Hospital",
    image: Img23,
    about: [
      "Dr. Binaya Kumar Jaiswal is a qualified Neurologist with a DM in Neurology, an MD in General Medicine and an MS in Orthopedics.",
      "He specializes in Diagnosing and Treating Neurological Disorders such as Epilepsy and Stroke, while his background in General Medicine allows for a holistic approach to patient care.",
      "Dr. Jaiswal is dedicated to continuous learning and provides compassionate, personalized treatment to his patients.",
    ],
    education: [
      "DM Neurology:- All India Institute of Medical Sciences, Bhubaneswar (2019- 2021).",
      "MD General Medicine:- M.K.C.G Medical College and Hospital, Berhampur (2015-2018).",
      "MBBS:- IMS & SUM Hospital, Bhubaneswar (2008-2014).",
      "Higher Secondary education:- Kuchinda College, Kuchinda, Sambalpur (2005-2007).",
      "High School education:- B.H.S Kusumi, Sambalpur (2005).",
    ],
    experience: [
      "Consultant Neurologist at Sunshine Hospital, Bhubaneswar (March 2022 to August 2022) - In this role, Dr. Jaiswal provided expert Neurological care, Diagnosing and Treating various conditions while working collaboratively with other healthcare professionals to ensure comprehensive patient management.",
      "Consultant Neurologist at Padmini care Hospital, Tangi, Cuttack, Odisha from September (2022-2024) - At Padmini Care Hospital, he continued to expand his expertise in Neurology, offering specialized treatments and conducting assessments for patients with diverse Neurological Disorders, while also participating in patient education and community outreach.",
      "Consultant Neurologist at Ashwini Hospital, Cuttack from 2024 - Currently, Dr. Jaiswal serves as a Consultant Neurologist at Ashwini Hospital, where he focuses on providing high-quality Neurological care, implementing personalized treatment plans, and remaining engaged in ongoing professional development to stay abreast of advancements in the field.",
    ],
    memberships: [],
    specialization: ["Neurology"],
  },
  "dr.monalipattnaik": {
    name: "Dr. Monali Pattnaik",
    qualification: "M.B.B.S , MD , DNB ",
    position: "Consultant",
    department: "Ashwini Wellness",
    image: Img30,
    about: ["Clinical Dermatologist and Aesthetic Surgeon."],
    education: ["M.B.B.S", "MD", "DNB"],
    experience: ["Having 12 years of experince."],
    memberships: ["IADVL", "IJD ", "IJPD ", "ACSI "],
    specialization: [
      "Clinical & Pediatric Dermatology",
      "Lasers & Aesthetic Surgeries ",
      "Cosmetic Surgery ",
    ],
  },
  "dr.sampatdash": {
    name: "Dr. Sampat Dash",
    qualification: "MD, DNB, IDCCM, MNAMS, EDRM ",
    position: "HOD, Senior Consultant - Respiratory Medicine",
    department: "Ashwini Hospital , Aditya Ashwini Hospital",
    image: Img31,
    about: [
      "Dr. Sampat Dash is a highly qualified Pulmonologist based in Cuttack, Odisha, with expertise in Respiratory Medicine.",
      "He holds an MD in Respiratory Medicine from SCB Medical College and a DNB in the same field.",
      "In addition, he has earned certifications from ISCCM, the European Diploma in Adult Respiratory Medicine (EDARM), and a Diploma in Infectious Diseases from New South Wales University, Australia.",
      "Dr. Dash is passionate about staying updated on the latest advancements in his field to provide the best possible care to his patients.",
    ],
    education: [
      "Completed MD in Respiratory Medicine from the prestigious SCB Medical College.",
      "Also have degree in DNB Respiratory Medicine.",
      "Awarded with certification course from ISCCM, from European Diploma in Adult Respiratory Medicine and Diploma in Infectious Diseases from New South Wales University- Australia.",
    ],
    experience: [
      "Apollo Hospital, Bhubaneshwar (2 years) Gained initial experience in Pulmonary Medicine, focusing on Diagnosis and Treatment of Respiratory conditions in a fast-paced hospital environment.",
      "AMRI Hospitals, Bhubaneshwar (3 years) Enhanced Clinical Skills and expertise in managing complex Respiratory Cases, collaborating with a multidisciplinary team for comprehensive patient care.",
      "Ashwini Hospital (6 years) Currently providing specialized care in Respiratory Medicine, applying the latest treatments and techniques.",
    ],
    memberships: ["OCS", "ICS ", "ISCCM ", "ERS ", "IAB "],
    specialization: ["Pulmonary Critical Care", "Interventional Pulmonology"],
  },
  "dr.nabakumarpattnaik": {
    name: "Dr. Naba Kumar Pattnaik",
    qualification: "MS, Mch, FIACS",
    position: "Consultant",
    department: "Ashwini Hospital",
    image: Img32,
    about: [
      "Dr. Naba Kumar Pattnaik is a highly experienced Cardiothoracic and Vascular Surgeon with 39 years of expertise in performing Complex Surgeries.",
      "He holds qualifications in M.B.B.S, MS (General Surgery), and MCh (CTVS), and is specialized in Cardiac Surgery for both Congenital and Acquired Heart Diseases.",
      "His extensive experience and skill in Cardiothoracic and Vascular Surgery have made him a leading figure in the field.",
    ],
    education: ["M.B.B.S(1976)", "MS(Gen. Surgery) 1980", "Mch(CTVS) 1984"],
    experience: [
      "Professor & HOD at SCB Medical College & Hospital (CTVS Dept.): Served for 10 years, where he played a key role in starting the MCh course for Cardiothoracic and Vascular Surgery.",
      "PG Teacher for DNB (CTVS) at Apollo Hospital, Bhubaneswar: Taught and mentored postgraduate students in Cardiothoracic and Vascular Surgery for 3 years.",
      "Faculty Member at MKCG Medical College, Berhampur: Contributed as a Faculty Member in the CTVS department, sharing his expertise with Medical Students and Residents.",
      "CTVS Consultant at Ashwini Hospital, Cuttack: Has been working for the last 15 years as a Cardiothoracic and Vascular Surgery Consultant, handling complex Cardiac Surgeries and Vascular Procedures.",
      "Expertise in Cardiac Surgery: Specializes in Surgeries for both Congenital and Acquired Heart Diseases, bringing 39 years of experience to the field.",
    ],
    memberships: ["Life member IACTS", "FIACS ", "Life member IMA "],
    specialization: [
      "Cardiac Surgery both Congenital & Aquired Heart Disease.",
      "Surgery on Lung, Oesophagus & Mediastinal growth.",
      "Vascular Surgery.",
    ],
  },
  "dr.satyamohanty": {
    name: "Dr. Satya Ranjan Mohanty",
    qualification: "MD",
    position: "Senior Consultant",
    department: "Ashwini Hospital , Aditya Ashwini Hospital",
    image: Img33,
    about: [
      "Skilled and dedicated Consultant in Pulmonary Medicine with huge experience in Diagnosing and Treating wide range of Respiratory problems  both on Outpatient and In patient basis.",
      "Having strong educational background and extensive clinical experience is committed to advance Pulmonary Medicine in the region.",
      "Active both in Patient Care and Medical Education, often engaging in Community Health Initiatives  to raise awareness about Respiratory health issues.",
    ],
    education: ["FCCS", "IDCCM"],
    experience: ["Having A 12 years of experince."],
    memberships: [
      "Odisha Chest Society.",
      "Indian Medical Association. ",
      "Indian Society of Critical Care Medicine. ",
    ],
    specialization: [
      "Respiratory Medicine.",
      "Respiratory critical care.",
      "Interventional Pulmonology.",
      "Critical Care Medicine.",
    ],
  },
  "dr.soumyarajghosh": {
    name: "Dr. Soumyaraj Gosh",
    qualification: "MD, IDCCM",
    position: "Consultant",
    department: "Ashwini Hospital",
    image: Img34,
    about: [
      "Dr. Soumyaraj Ghosh is a highly skilled Consultant in Critical Care, holding qualifications in MD and IDCCM.",
      "He is certified in Critical Care and MD Anesthesiology, with a specialization in Trauma, Toxicology and managing Acute Emergencies.",
      "Dr. Ghosh's expertise ensures that patients in Critical conditions receive the highest level of care, particularly in complex and life-threatening situations.",
    ],
    education: ["Critical Care", "IDCCM", "MD Anesthesiologist "],
    experience: [
      "Soumyaraj Ghosh has been serving as a Consultant in Critical Care at Ashwini Hospital since 2017.",
    ],
    memberships: ["Indian Society of Critical Care Medicine"],
    specialization: ["Trauma", "Toxicology", "Acute Emergencies"],
  },
  "dr.sandippradhan": {
    name: "Dr. Sandip Kumar Pradhan",
    qualification: "MS ( General Surgery)",
    position: "Consultant",
    department: "Ashwini Hospital",
    image: Img35,
    about: [
      "Dr. Sandip Kumar Pradhan is a skilled Surgeon with an MS in General Surgery from CMC Ludhiana.",
      "He specializes in Gastrointestinal (GI) Surgery and Hepatobiliary Surgery, focusing on conditions related to the Digestive System and Liver.",
      "His expertise encompasses a range of Surgical Procedures, ensuring High-Quality care for patients with Complex Gastrointestinal and Hepatobiliary Issues.",
      "Dr. Pradhan is committed to advancing Surgical Techniques and improving patient outcomes in his field.",
    ],
    education: ["M.B.B.S", "MS ( General Surgery) CMC Ludhiana , Panjab"],
    experience: [
      "Dr. Sandip Kumar Pradhan has a rich professional history as a Consultant Surgeon and CEO in various mission hospitals across India.",
      "Sewa Bhawan Hospital (1982-1996): Served as CEO, overseeing hospital Operations and enhancing Patient Care.",
      "Christian Medical College & Hospital (1992-1996): Completed his training as a student, gaining valuable Surgical knowledge.",
      "Madhepura Christian Hospital (1996-2000): Functioned as CEO, managing healthcare services and staff.",
      "The Leprosy Mission Hospital, Purulia (2000-2002): Acted as CEO, focusing on specialized care for Leprosy patients.",
      "Catherine Hospital, Kanpur (2002-2006): Held the position of CEO, implementing strategic improvements in hospital management.",
      "Christian Hospital, Nabarangpur (2006-2012): Worked as Senior Consultant, ensuring quality healthcare delivery.",
      "Ashwini Hospital (2012-Present): Currently serves as a Senior Consultant, utilizing his expertise in GI and Hepatobiliary Surgery to provide patient-centered care.",
    ],
    memberships: ["Faculty in Emergency Medicine Ashwini Hospital. "],
    specialization: ["GI Surgery and Hepatobiliary Surgery."],
  },
  "dr.alokkumarmohapatra": {
    name: "Dr. Alok Kumar Mohapatra",
    qualification: "M.B.B.S , MD Medicine , DM Nephrology ",
    position: "Consultant",
    department: "Ashwini Hospital , Aditya Ashwini Hospital",
    image: Img36,
    about: [
      "Dr. Alok Kumar Mohapatra is a highly qualified Nephrologist and Kidney Transplant Physician, holding degrees in M.B.B.S, MD in Medicine and DM in Nephrology.",
      "Extensive expertise in managing Kidney-related Disorders and performing Transplants, Dr. Mohapatra provides specialized care to patients with Chronic Kidney Disease and other Neurological conditions.",
    ],
    education: ["M.B.B.S ", "MD Medicine", "DM Nephrology"],
    experience: [
      "Dr. Alok Kumar Mohapatra is a highly qualified Nephrologist and Kidney Transplant Physician. With 2 years of experience at Ashwini Group of Hospitals, he specializes in managing Kidney-related Disorders and performing Transplants.",
    ],
    memberships: ["International society of Neprology", "ISN", "ISOT"],
    specialization: [
      "Kidney Medicine and Renal Transplant. ",
      "Critical Care Nephrology and Intervention Nephrology. ",
    ],
  },
  "dr.sudhakarjena": {
    name: "Dr. Sudhakar Jena",
    qualification: "DNB, M.Ch, M.B.B.S, M.S",
    position: "Consultant",
    department: "Ashwini Trauma Centre",
    image: Img39,
    about: [
      "Dr. Sudhakar Jena is a skilled Medical Professional with degrees in DNB, M.Ch, M.B.B.S, and M.S, specializing in Burn and Plastic Surgery.",
      "He has extensive expertise in Reconstructive Procedures, Cosmetic Enhancements, and Burn Management",
      "Known for his precision and commitment to patient care, Dr. Jena utilizes advanced Surgical Techniques to improve patient outcomes, establishing himself as a trusted figure in Plastic Surgery and contributing to the advancement of his specialty.",
    ],
    education: ["M.B.B.S ", "DNB ", "M.Ch ", "M.S "],
    experience: [
      "Senior Consultant (2024-Present) Ashwini Trauma Centre, Cuttack.",
      "Provides specialized care in Burns, Plastic, Reconstructive, and Aesthetic Surgery, while focusing on patient education and safety.",
      "Consultant and Head of Burns and Plastic Department (2019-2024) Tata Steel Medica Hospital, Kalinganagar.",
      "Led the department, focusing on Burn Care and Reconstructive Surgeries, while mentoring junior staff.",
      "Senior Resident (2019) IPGMER & SSKM Hospital, Kolkata.",
      "Managed complex Burn Cases and Assisted in Advanced Reconstructive Surgeries.",
      "Senior Resident (2014-2015) MKCG Medical College and Hospital, Berhampur.",
      "Gained extensive experience in Emergency Burn Care and Elective Plastic Surgeries.",
    ],
    memberships: [],
    specialization: ["Burn & Plastic Surgeon. "],
  },
  "dr.geetamohanty": {
    name: "Dr. Geeta Mohanty",
    qualification: "DM Neurology , MD General Medicine , M.B.B.S",
    position: "Senior Consultant",
    department: "Ashwini Hospital",
    image: Img40,
    about: [
      "Dr. Geeta Mohanty has over 28 years of experience in Neurology.",
      "She specializes in treating conditions like Stroke, Epilepsy and Neurodegenerative diseases.",
      "Known for her compassionate and patient-centered care, she creates personalized treatment plans.",
      "Dr. Mohanty also mentors aspiring Neurologists and contributes to Neurological research.",
      "She consistently incorporates the latest advancements in Neurology to enhance patient outcomes.",
    ],
    education: ["M.B.B.S ", "DM Neurology ", "MD General Medicine "],
    experience: [
      "Lecturer at VSS Medical College, Burla (1996-2001)– Dr. Geeta Mohanty began her academic career, teaching and shaping the next generation of medical students in Neurology during this period.",
      "Assistant Professor at VSS Medical College (2001-2005) – She advanced to Assistant Professor, furthering her academic contributions and deepening her expertise in Neurology education and patient care.",
      "Associate Professor at VSS Medical College (2005-2009)– As an Associate Professor, she took on more responsibilities in both teaching and research, contributing to the development of Neurology studies.",
      "Professor & HOD at VSS Medical College (2009-2010) – Dr. Mohanty led the Neurology department as Head of Department, managing departmental operations and patient care services.",
      "Professor at SCB Medical College (2010-2022) – She spent over a decade as a Professor, making significant contributions to medical education, research, and Neurology practice at one of the leading medical institutions.",
      "Professor at SCB Hospital (2022-2023) – During this time, she continued her work in Clinical Neurology, providing expert care to patients with complex Neurological conditions.",
      "Senior Consultant at Ashwini Hospital (2023-present) – Currently, she is a Senior Consultant, offering her vast experience and specialized knowledge to provide high-quality care to patients.",
    ],
    memberships: [],
    specialization: ["Neurology"],
  },
  "dr.satyanarayanbehera": {
    name: "Dr. Satya Narayan Behera",
    qualification: "M.B.B.S (SCB) , MS Orthopedics , Senior Residency In MAMC",
    position: "Consultant",
    department: "Ashwini Trauma Centre",
    image: Img41,
    about: [
      "Dr. Satya Narayan Behera is a highly qualified Orthopedic Surgeon with an M.B.B.S from SCB Medical College and an MS in Orthopedics.",
      "He completed his Senior Residency at Maulana Azad Medical College (MAMC) in New Delhi.",
      "Dr. Behera has pursued Advanced Fellowships in Revision Hip and Knee Replacement, Robotics at Viera Hospital in Orlando, USA, and Shoulder and Knee Replacement in South Korea.",
      "He also completed Fellowships in Shoulder and Knee Arthroscopy.",
      "His specialization includes Joint Replacement Surgeries for the Hip, Knee, Shoulder, and Elbow, as well as Arthroscopic procedures for various Joints.",
      "He is experienced in managing complex Acetabular and Pelvic Fractures.",
    ],
    education: [
      "M.B.B.S (SCB) .",
      "MS Orthopedics.",
      "Senior Residency In MAMC, New Delhi.",
      "Fellowship In Revision Hip, Knee Replacement, Uni Condylar Knee Replacement (UKR) Robotics In USA 🇺🇸 Viera Hospital, Orlando. ",
      "Fellowship In Shoulder Replacement And Knee Replacement (South Korea). ",
      "Fellowship In Shoulder Arthroscopy, Knee Arthroscopy (South Korea, Bundang Hospital). ",
    ],
    experience: [
      "Consultant at Ashwini Trauma Center (2018 – Present) :-Responsible for providing expert Orthopedic care, focusing on Trauma management and Joint Reconstruction.",
      "Senior Residency:- Maulana Azad Medical College, New Delhi (2013 – 2016) :- Gained extensive clinical experience in Orthopedic Surgery, managing a variety of cases under expert supervision.",
      "Observership:-Arthroscopy at Safdarjung Hospital, New Delhi(2017):-Focused on advanced Arthroscopic techniques, enhancing skills in minimally invasive procedures.",
      "Attending Consultant:-Maxx Hospital, Delhi (2017 – 2018):-Provided Orthopedic Consultation and Surgical care, further developing expertise in Joint Surgeries.",
      "Fellowship in Revision Hip and Knee Replacement, Robotics:-Viera Hospital, Orlando, USA (2024):-Specialized in advanced techniques for Revision Surgeries and Robotic-assisted Joint Replacement procedures.",
      "Fellowship in Shoulder and Knee Replacement:-South Korea (2018 – 2019):-Focused on the latest methodologies in shoulder and Knee Replacement Surgeries, enhancing Surgical skills.",
      "Fellowship in Shoulder and Knee Arthroscopy, Sports Medicine:-Bundang Hospital, South Korea (2018 – 2019):-Trained in minimally invasive Arthroscopic Techniques and Sports Medicine, improving treatment outcomes for Joint Injuries.",
    ],
    memberships: ["OAS", "IOA"],
    specialization: [
      "Joint Replacement (Hip, Knee, UKR, Shoulder, and Elbow). ",
      "Arthroscopy (Shoulder, Wrist, Knee, and Ankle). ",
      "Complex Acetabular Fractures and Pelvis Fractures. ",
    ],
  },
  "dr.prasannakumarmishra": {
    name: "Dr. Prasanna Kumar Mishra",
    qualification: "M.B.B.S, MD",
    position: "HOD, Senior Consultant - Emergency Medicine",
    department: "Ashwini Hospital",
    image: Img42,
    about: [
      "Dr. Prasanna Kumar Mishra is a qualified Medical Professional with an M.B.B.S and MD, specializing in Cardiac Anesthesia and Hemodynamics.",
      "He has served as a Professor and Head of the Department (HOD) of Emergency Medicine and is now retired from his role as a Professor of Anesthesia.",
      "Dr. Mishra is recognized for his expertise in Anesthesia Management during Cardiac Procedures and his contributions to Emergency Medicine, significantly impacting the education and training of medical professionals in these fields.",
    ],
    education: ["M.B.B.S.", "MD."],
    experience: [
      "Resident Post Graduate SCB Medical College 1977- 1979.",
      "Lecturer/ Tutor  Anesthesiology at VSs Medical College Sept 1981-Jun 1991.",
      "Lecturer/ Tutor  Anesthesiology at SCB Medical College Jun 1991- Jan 1997.",
      "Assst Prof. Anesthesiology at MKCG Medical College Jan 1997-Sept 1999.",
      "Assst Prof. Anesthesiology at SCB Medical College Sept 1999-Jun 2002.",
      "Associate Prof. Anesthesiology SCB Medical College Jun 2002-Jun 2006.",
      "Professor of Anesthgesiology SCB Medical College Jun 2006-Dec2009.",
      "Professor of Anesthgesiology VSS Medical College Dec 2009-Oct 2011.",
      "Professor of Anesthgesiology , K I M S, Bhubaneswar , July 2012-Feb 2015.",
      "Professor of Anesthesiology at Apollo Hospital, Bhubaneswar Mar 2015-Mar 2019.",
      "Consultant Anesthesia and Critical Care , Ashwini Hospital.",
      "Accredited Teacher IDCCM, IDCCN, Ashwini Hospital, Cuttack March 2019-2022.",
      "Currently serving as Professor and HOD of Emergency Medicine at Ashwini Hospital, Cuttack (2021 - Present).",
    ],
    memberships: ["IMA", "ISA", "ISCCM"],
    specialization: ["Cardian Anesthesia ", "Hemodynamics"],
  },
  "dr.samirsahu": {
    name: "Dr. Samir Sahu",
    qualification: "MD, FICCM",
    position: "HOD, Senior Consultant - Critical Care",
    department: "Ashwini Hospital",
    image: Img43,
    about: [
      "Dr. Samir Sahu is a highly qualified medical professional with an MD and FICCM (Fellow of the Indian College of Critical Care Medicine).",
      "He serves as a Senior Consultant in Pulmonology and Critical Care and has established himself as a prominent National Faculty Member in these areas.",
      "Dr. Sahu holds the unique distinction of being the first person in Odisha to introduce Critical Care Medicine, Noninvasive Ventilation, and Sleep Medicine.",
      "He has an impressive track record of research, having authored 30 publications, demonstrating his dedication to advancing knowledge and innovation in his field.",
      "Additionally, he plays a significant role as a Teacher in DrNB (Doctorate of National Board) and IDCCM (Indian Diploma in Critical Care Medicine) Critical Care programs.",
    ],
    education: [
      "MD (Medicine)-1983",
      "Training In Critical Care Medicine-1990",
      "Training In Pulmonology-1995",
    ],
    experience: [
      "Dr. Samir Sahu began his Medical Career in 1983, marking the start of his 40 years journey in the field.",
      "By 1990, he had ventured into Critical Care Medicine, accumulating 33 years of expertise in managing critically ill patients.",
      "In 1993, he expanded his specialization to Pulmonology, amassing 30 years of experience in Diagnosing and Treating Respiratory conditions.",
      "Over the decades, Dr. Sahu's vast experience has made him a trusted authority in both Critical Care and Pulmonology.",
    ],
    memberships: [
      "Indian Medical Association (IMA)",
      "Association of Physicians India (API)",
      "Indian Society of Critical Care Medicine (ISCCM)",
      "Indian Chest Society (ICS)",
    ],
    specialization: [
      "Critical Care Medicine",
      "Asthma/COPD",
      "Obstructive Sleep Apnoea",
      "Noninvasive Ventilation",
    ],
  },
  "dr.samadritaroy": {
    name: "Dr. Samadrita Roy",
    qualification: "M.B.B.S, MD",
    position: "Senior Consultant",
    department: "Aditya Ashwini Hospital, Ashwini Wellness",
    image: Img44,
    about: [
      "Dr. Samadrita Roy: MBBS, MD, specializing in Neonatology and Pediatrics.",
      "Expert in the care of Newborns, particularly Premature and critically ill Infants.",
      "She provides comprehensive medical care for Infants and Children.",
    ],
    education: [
      "MD Pediatrics from ST Philomena Hospital Bangalore.",
      "M.B.B.S from Raja Rajeswari Medical College and Hospital Bangalore",
      "NRP trained  from St John's Medical College and Hospital.",
      "PALS and BLS trained from MS Rammaiah Medical College and Hospital.",
    ],
    experience: [
      "Vasavi Superspeciality Hospital: Provided specialized Pediatric care.",
      "Manipal Hospital: Gained diverse clinical experience in a multispecialty setting.",
      "Comfort Multi Speciality Hospital: Further developed skills in Pediatric Healthcare.",
      "Aditya Ashwini Hospital: As Senior Consultant in Pediatric healthcare.",
    ],
    memberships: ["Indian Academy of Pediatrics", "Indian Medical Association"],
    specialization: ["Neonatology", "Pediatrics"],
  },
  "dr.manoranjanacharya": {
    name: "Dr. Manoranjan Acharya",
    qualification: "DM(Neurology)",
    position: "Senior Consultant",
    department: "Ashwini Hospital",
    image: Img45,
    about: [
      "Dr. Manoranjan Acharya, a DM-qualified neurologist with 20 years of experience, specializes in treating a wide range of Neurological conditions.",
      "Known for his expertise and compassionate care, he is dedicated to improving patients' lives through advanced Neurology practices.",
    ],
    education: ["DM(Neurology)"],
    experience: [
      "Ashwini Hospital as Sr. Consultant of Neurology 2024-Till Date.",
      "VSS Medical College of Burla Prof. of Neurology 2010-2024",
      "SCBMCH,Cuttack Associate Prof. of Neurology 2008-2010.",
      "MKCG MCH Behrempur Assistant Prof. of Neurology 2006-2008.",
      "SCB MCH Cuttack as Assistant Prof. Neurology 2004-2006.",
      "SCB MCH Cuttack as Lecture of Neurology 2000-2004.",
    ],
    memberships: [],
    specialization: ["Neurology Diseases"],
  },
  "dr.subratkumarjena": {
    name: "Dr. Subrat Kumar Jena",
    qualification: "M.S(Ortho), M.Ch(Plastic Surgery)",
    position: "HOD, Senior Consultant - Plastic Surgery",
    department: "Ashwini Trauma Centre",
    image: Img46,
    about: [
      "Dr. Subart Kumar Jena is a revered and dedicated Plastic Surgeon whose life has been a testament to selfless service and unwavering commitment to his community.",
      "After completing his studies, he gained valuable experience in the field, yet his vision extended beyond traditional practice.",
      "Driven by a profound desire to provide high-quality healthcare to all, Dr. Jena made the extraordinary decision to devote everything he had to build his own hospital.",
      "With immense personal sacrifice, he established Ashwini Hospital, creating a sanctuary of healing where countless patients have found solace and care.",
      "His work did not end there; he continued to expand his mission, establishing the Ashwini Trauma Centre to provide Critical Care to those in urgent need. Dr. Jena’s dedication to his calling has transformed lives and set a high standard for compassionate healthcare.",
      "His legacy is one of humility, resilience, and enduring impact—an inspiration for all who seek to make a difference through their life’s work.",
      "Dr. Jena’s story is not just about a career; it is about a lifelong commitment to the well-being of others, a commitment that continues to inspire his community and peers alike.",
    ],
    education: ["M.S(Ortho).", "M.Ch(Plastic Surgery)."],
    experience: [
      "BG Accident Hospital (Unfallklinik) - Germany (2000-2001). At Germany’s Unfallklinik, he specialized in accident and trauma-related plastic surgery, acquiring cutting-edge techniques in reconstructive surgery.",
      "West Midlands Regional Joint Plastic Surgery Program - Central UK, Stourbridge (1989-1990). Dr. Jena trained in the UK, where he gained international exposure to advanced plastic surgery techniques, further enhancing his skills.",
      "Founder and Director, Ashwini Trauma Centre (2019-Present). In response to the growing need for Trauma Care, Dr. Jena expanded his mission by founding the Ashwini Trauma Centre, offering Comprehensive Care for Critical and Emergency cases.",
      "Founder and Director, Ashwini Hospital (2006-2019). Driven by a vision to provide top-notch healthcare, Dr. Jena established Ashwini Hospital. Over the years, it became a trusted medical institution known for excellence in Plastic and Reconstructive Surgery.",
      "Plastic Surgeon, Dr. House Clinic (2006). Briefly serving at Dr. House Clinic, he brought high standards of patient care and surgical precision.",
      "Consultant in Plastic Surgery, Kalinga Hospital (1996-2000). Dr. Jena transitioned to a consultancy role at Kalinga Hospital, serving patients with complex surgical needs and establishing himself as a skilled and trusted Plastic Surgeon.",
      "Lecturer in Plastic Surgery, MKCG Medical College, Berhampur (1992-1996). He continued his teaching journey at MKCG Medical College, where he further developed his expertise and mentored students in advanced Plastic Surgery.",
      "Lecturer in Plastic Surgery, SCB Medical College (1986-1992). Dr. Jena began his academic career as a lecturer, where he shared his knowledge with aspiring surgeons and helped advance Plastic Surgery techniques.",
    ],
    memberships: [
      "Association of Surgeons of India (ASI).",
      "Association of Plastic Surgeons of India (APSI).",
      "Indian Society for Surgery of the Hand (ISSH).",
      "Indian Society for Reconstructive Microsurgery (ISRM).",
    ],
    specialization: [],
  },
};

export default doctorsData;
