import React, { useState } from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";
import G1 from "../../../assets/department photos/criticalcare1.png";
import G2 from "../../../assets/department photos/criticalcare2.jpg";
import G5 from "../../../assets/department photos/criticalcare5.jpg";
import G19 from "../../../assets/department photos/criticalcare1.png";
import G23 from "../../../assets/department photos/criticalcare24.jpg";
import G24 from "../../../assets/department photos/physio-therapy-3.jpg";
import G25 from "../../../assets/department photos/neuro3.png";
import G26 from "../../../assets/department photos/pharmacy.jpg";
import G27 from "../../../assets/department photos/criticalcare25.jpg";
import G28 from "../../../assets/department photos/criticalcare26.jpg";

function AAHCriticalCare() {
  const gallery = [{ image: G1, caption: "Clinical Round" }];

  const [currentImage, setCurrentImage] = useState("");
  const [currentCaption, setCurrentCaption] = useState("");

  const openModal = (image, caption) => {
    setCurrentCaption(caption);
    setCurrentImage(image);
    const modalElement = document.getElementById("exampleModal");
    const modal = new window.bootstrap.Modal(modalElement);
    modal.show();
  };

  return (
    <>
      <NavbarLocation />

      {/* Critical Care Doctors */}
      <div className="doctors-box">
        <div className="container"></div>
      </div>

      {/* Section */}
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12 mb-3 hero-section">
            {/* gallery */}
            <div className="card">
              <div className="card-body">
                <h2 className="card-title widget-title mb-3">Media</h2>
                <div className="widget-content clearfix">
                  <ul className="image-list">
                    {gallery.map((g, index) => (
                      <li key={index}>
                        <figure>
                          <img
                            src={g.image}
                            alt={g.caption}
                            title={g.caption}
                            onClick={() => openModal(g.image, g.caption)}
                          />
                        </figure>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* Modal */}
            <div
              className="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Image Preview
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p>{currentCaption}</p>
                    <img
                      src={currentImage}
                      alt={currentCaption}
                      title={currentCaption}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
            <div className="card">
              <div className="card about-style-two py-0">
                <div className="container card-body">
                  <div className="section-1">
                    <h3 className="sec-title">
                      Department of Critical Care at Ashwini Group
                    </h3>
                    <h5 className="fs-6 fw-bold text-primary">
                      About the Department & Achievements
                    </h5>
                    <p>
                      <strong>
                        The Critical Care Department at Ashwini Group Of
                        Hospitals, Odisha
                      </strong>
                      , is a state-of-the-art facility specializing in advanced{" "}
                      <strong>
                        Intensive Care, DrNB Critical Care training, and
                        Emergency life support
                      </strong>
                      . Our{" "}
                      <strong>
                        dedicated team of Intensivists, ICU Nurses,
                        Physiotherapists, and Dietitians
                      </strong>{" "}
                      provides <strong>24/7</strong> life-saving interventions
                      for critically ill patients.
                    </p>
                    <p>
                      We specialize in managing{" "}
                      <strong>
                        Multi-organ Failure, Sepsis, Acute Respiratory Distress
                        Syndrome (ARDS), Neurological Emergencies,
                        Post-operative complications
                      </strong>
                      , and other complex critical care conditions.
                    </p>
                    <h5 className="fs-6 fw-bold text-primary">
                      Key Achievements
                    </h5>
                    <ul className="list-unstyled ">
                      <li>
                        &#9989;Successfully treated <strong>Thousands</strong>{" "}
                        of critically ill patients with exceptional survival
                        outcomes.
                      </li>
                      <li>
                        &#9989;Recognized as a training center for{" "}
                        <strong>
                          DrNB Critical Care, IDCCM, CTCCM, and ISCCN programs
                        </strong>
                        , fostering the next generation of critical care
                        specialists.
                      </li>
                      <li>
                        &#9989;Conducted national-level{" "}
                        <strong>workshops on ARDS</strong> management, advanced
                        life support, and ventilator care protocols.
                      </li>
                      <li>
                        &#9989;Implemented <strong>cutting-edge</strong>{" "}
                        infection control measures, ventilator management
                        protocols, and patient safety initiatives.
                      </li>
                      <li>
                        &#9989;<strong>24/7</strong> intensivist coverage,
                        ensuring real-time <strong>Emergency Response</strong>{" "}
                        and advanced decision-making.
                      </li>
                    </ul>
                    <div className="container card-body">
                      <h3 className="sec-title">
                        Infrastructure & Specialties
                      </h3>
                      <p>
                        The Critical Care Department at Ashwini Hospital is
                        equipped with <strong>state-of-the-art ICU</strong>{" "}
                        infrastructure, ensuring the highest standards of
                        critical care, infection control, and multi-organ
                        support therapies.
                      </p>
                      <h5 className="fs-6 fw-bold text-primary">
                        Infrastructure Highlights
                      </h5>
                      <ul className="custom-style">
                        <li>
                          <strong>29-bed Central ICU</strong>, along with a
                          dedicated <strong>8-bed Neuro ICU</strong> and a{" "}
                          <strong>10-bed Cardiac ICU</strong>, ensuring
                          specialized intensive care for critically ill
                          patients.
                        </li>

                        <li>
                          Advanced ventilators, ECMO support, CRRT (Continuous
                          Renal Replacement Therapy), and high-end hemodynamic
                          monitoring.
                        </li>

                        <li>
                          State-of-the-art imaging and diagnostics, including
                          bedside ultrasound, echocardiography, and
                          bronchoscopy.
                        </li>

                        <li>
                          <strong>Dedicated isolation units</strong> for
                          managing highly infectious diseases.
                        </li>

                        <li>
                          24/7 pharmacy and laboratory support for instant
                          diagnostics and critical care medication.
                        </li>
                      </ul>
                      <div className="row text-center">
                        {[
                          { image: G5, caption: "Critical Care Ward" },
                          { image: G23, caption: "Dialysis Machine" },
                          { image: G26, caption: "Pharmacy" },
                        ].map((facility, index) => (
                          <div key={index} className="col-md-4 mb-4">
                            <div className="card h-100 shadow-sm">
                              <img
                                src={facility.image}
                                alt={facility.caption}
                                title={facility.caption}
                                className="img-fluid"
                                style={{ height: "200px", objectFit: "fill" }}
                                onClick={() =>
                                  openModal(facility.image, facility.caption)
                                }
                              />
                              <div
                                className="p-2"
                                style={{ backgroundColor: "aliceblue" }}
                              >
                                <h5 className="fs-6 text-primary">
                                  {facility.caption}
                                </h5>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <h5 className="fs-6 fw-bold text-primary">
                        Specialties Offered
                      </h5>
                      <ul className="list-unstyled">
                        <li>
                          &#9989;<strong>DrNB Critical Care Medicine</strong>{" "}
                          training with hands-on experience.
                        </li>

                        <li>
                          &#9989;Emergency airway management and{" "}
                          <strong>Advanced Hemodynamic Stabilization</strong>.
                        </li>

                        <li>
                          &#9989;<strong>Respiratory Sailure Management</strong>
                          , including non-invasive & invasive ventilation.
                        </li>

                        <li>
                          &#9989;Multi-disciplinary critical care involving
                          Pulmonology, Cardiology, Nephrology, and Neurology.
                        </li>

                        <li>
                          &#9989;Post-operative intensive care for smooth
                          recovery after major surgeries.
                        </li>

                        <li>
                          &#9989;Organ support therapies, including CRRT,{" "}
                          <strong>Plasma Exchange</strong>, and extracorporeal
                          life support (ECLS).
                        </li>
                      </ul>
                    </div>
                    <div className="container card-body">
                      <h3 className="sec-title">
                        Patient Care & Specialized Services
                      </h3>
                      <p>
                        At Ashwini Hospital, we follow a patient-centric
                        approach, ensuring the highest quality of intensive care
                        with a focus on{" "}
                        <strong>Safety, Comfort, and Rapid Recovery</strong>.
                      </p>
                      <h5 className="fs-6 fw-bold text-primary">
                        Comprehensive Patient Care
                      </h5>
                      <ul className="custom-style">
                        <li>
                          <strong>24/7 monitoring</strong> by intensivists and
                          skilled ICU nurses.
                        </li>

                        <li>
                          <strong>Individualized Treatment Protocols</strong>{" "}
                          for optimized patient recovery.
                        </li>

                        <li>
                          <strong>
                            Physiotherapy & Rehabilitation Programs
                          </strong>{" "}
                          for faster post-ICU recovery.
                        </li>

                        <li>
                          <strong>Family Counseling Sessions</strong>, keeping
                          caregivers informed and involved.
                        </li>

                        <li>
                          <strong>Pain Management Services</strong>, ensuring
                          maximum patient comfort.
                        </li>
                      </ul>
                      <div className="row text-center">
                        {[
                          { image: G2, caption: "ICU Care" },
                          { image: G19, caption: "Clinical Round" },
                          { image: G24, caption: "Physiotherapy" },
                        ].map((facility, index) => (
                          <div key={index} className="col-md-4 mb-4">
                            <div className="card h-100 shadow-sm">
                              <img
                                src={facility.image}
                                alt={facility.caption}
                                title={facility.caption}
                                className="img-fluid"
                                style={{ height: "200px", objectFit: "fill" }}
                                onClick={() =>
                                  openModal(facility.image, facility.caption)
                                }
                              />
                              <div
                                className="p-2"
                                style={{ backgroundColor: "aliceblue" }}
                              >
                                <h5 className="fs-6 text-primary">
                                  {facility.caption}
                                </h5>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <h5 className="fs-6 fw-bold text-primary">
                        Exclusive Critical Care Services
                      </h5>
                      <ul className="custom-style">
                        <li>
                          <strong>Bedside procedures:</strong> Bronchoscopy,
                          percutaneous tracheostomy, ultrasound-guided
                          interventions, and central line advanced monitoring
                          protocols.
                        </li>

                        <li>
                          <strong>Rapid Emergency Response:</strong> A dedicated
                          Code Blue team for immediate critical interventions.
                        </li>

                        <li>
                          <strong>Specialized Nutritional Support:</strong>{" "}
                          Enteral & parenteral nutrition for ICU patients.
                        </li>

                        <li>
                          <strong>Tele-ICU Consultations:</strong> Expert
                          opinions available remotely for seamless critical care
                          management.
                        </li>

                        <li>
                          <strong>Post-ICU Follow-up</strong> programs, ensuring
                          long-term recovery and rehabilitation.
                        </li>
                      </ul>
                      <div className="row text-center">
                        {[
                          { image: G27, caption: "Bedside Procedure" },
                          { image: G28, caption: "ICU Round" },
                          { image: G25, caption: "Post-ICU Follow-Ups" },
                        ].map((facility, index) => (
                          <div key={index} className="col-md-4 mb-4">
                            <div className="card h-100 shadow-sm">
                              <img
                                src={facility.image}
                                alt={facility.caption}
                                title={facility.caption}
                                className="img-fluid"
                                style={{ height: "200px", objectFit: "fill" }}
                                onClick={() =>
                                  openModal(facility.image, facility.caption)
                                }
                              />
                              <div
                                className="p-2"
                                style={{ backgroundColor: "aliceblue" }}
                              >
                                <h5 className="fs-6 text-primary">
                                  {facility.caption}
                                </h5>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <p>
                        At Ashwini Hospital, Odisha, our Critical Care
                        Department is committed to excellence in patient care,
                        continuous medical education, and research, making us a
                        leading center for DrNB Critical Care in India.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AAHCriticalCare;
