import React, { useState } from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";
import SEO from "../../SEO/SEO";
import Img1 from "../../../assets/doctors/sampat_dash.png";
import Img2 from "../../../assets/doctors/satya_mohanty.png";
import G1 from "../../../assets/department photos/Pulmonology1.png";

function AAHPulmonology() {
  const [openIndex, setOpenIndex] = useState(null);
  const [currentImage, setCurrentImage] = useState("");
  const [currentCaption, setCurrentCaption] = useState("");
  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle accordion open/close
  };

  const openModal = (image, caption) => {
    setCurrentCaption(caption);
    setCurrentImage(image);
    const modalElement = document.getElementById("exampleModal");
    const modal = new window.bootstrap.Modal(modalElement);
    modal.show();
  };
  const slides = [
    {
      image: Img1,
      name: "Dr. Sampat Dash",
      qualification: "MD, DNB, IDCCM, MNAMS, EDRM",
      location: "Ashwini Hospital, Aditya Ashwini Hospital",
    },
    {
      image: Img2,
      name: "Dr. Satya Mohanty",
      qualification: "MD, IDCCM Dept. Of Pulmonary Medicine",
      location: "Ashwini Hospital, Aditya Ashwini Hospital",
    },
  ];

  const gallery = [{ image: G1, caption: "" }];

  const opdTimings = [
    {
      doctor: "Dr. Sampat Dash",
      schedule: [
        {
          day: "MON",
          time: "03:00 PM - 05:00 PM",
          centre: "Aditya Ashwini Hospital",
        },
        {
          day: "FRI",
          time: "03:00 PM - 05:00 PM",
          centre: "Aditya Ashwini Hospital",
        },
      ],
    },
    {
      doctor: "Dr. Satya Mohanty",
      schedule: [
        {
          day: "MON",
          time: "09:30 AM - 05:00 PM",
          centre: "Aditya Ashwini Hospital",
        },
        {
          day: "TUE",
          time: "09:30 AM - 05:00 PM",
          centre: "Aditya Ashwini Hospital",
        },
        {
          day: "WED",
          time: "09:30 AM - 05:00 PM",
          centre: "Aditya Ashwini Hospital",
        },
        {
          day: "THU",
          time: "09:30 AM - 05:00 PM",
          centre: "Aditya Ashwini Hospital",
        },
        {
          day: "FRI",
          time: "09:30 AM - 05:00 PM",
          centre: "Aditya Ashwini Hospital",
        },
        {
          day: "SAT",
          time: "09:30 AM - 05:00 PM",
          centre: "Aditya Ashwini Hospital",
        },
      ],
    },
  ];
  return (
    <>
      <NavbarLocation />
      <SEO
        title="Best Pulmonary & Respiratory Medicine Hospital in Bhubaneswar | Asthma, COPD, Lung Cancer"
        description="Aditya Ashwini  Hospital offers expert care in respiratory medicine and sleep disorders, including asthma, COPD, interstitial lung disease, lung cancer, and TB. Equipped with PFT, bronchoscopy, sleep labs, and a dedicated respiratory critical care unit."
        keywords="Aditya Ashwini Hospital, Ashwini Hospital Pulmonology, Best Pulmonary Medicine Hospital in Odisha, DNB Respiratory Medicine India, COPD Treatment Odisha, Lung Cancer Specialist Cuttack, Asthma Hospital in Odisha, Interstitial Lung Disease Care, Respiratory Critical Care Hospital, Sleep Study in Odisha, Bronchoscopy Cuttack, Pulmonary Rehabilitation Odisha, Top Pulmonologist in Odisha, FENO Testing Odisha, Sleep Apnea Clinic Odisha"
      />
      {/* Pulmonology Doctors */}
      <div className="doctors-box">
        <div className="container">
          <div className="sec-title">
            <h1>Our Esteemed Consultants</h1>
          </div>

          <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
            {slides.map((slide, index) => (
              <div className="col-lg-3 col-md-4" key={index}>
                <a
                  className="doc-name"
                  href={`/Doctors/${slide.name
                    .replace(/\s+/g, "")
                    .toLowerCase()}`}
                >
                  <div className="card h-100 doctor_img">
                    <img
                      src={slide.image}
                      alt={`${slide.name} Img`}
                      title={slide.name}
                    />
                    <div className="doc-text">
                      <h3>{slide.name}</h3>
                      <p>{slide.qualification}</p>
                      <span className="text-dark">{slide.location}</span>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Section */}
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12 mb-3 hero-section">
            {/* opd timings */}
            <div className="card mb-3">
              <div className="card-body">
                <h2 className="card-title widget-title mb-3">OPD Timings</h2>
                <div className="widget-content">
                  {opdTimings.map((opd, index) => (
                    <div className="accordion-item" key={`opd-${index}`}>
                      <h2 className="accordion-header" id={`heading${index}`}>
                        <button
                          className={`accordion-button ${
                            openIndex === index ? "" : "collapsed"
                          }`}
                          type="button"
                          onClick={() => toggleAccordion(index)}
                          aria-expanded={openIndex === index ? "true" : "false"}
                          aria-controls={`collapse${index}`}
                        >
                          {opd.doctor}
                          <span className="toggle-text">
                            {openIndex === index ? "Hide" : "Show"}
                          </span>
                        </button>
                      </h2>
                      <div
                        id={`collapse${index}`}
                        className={`accordion-collapse collapse ${
                          openIndex === index ? "show" : ""
                        }`}
                        aria-labelledby={`heading${index}`}
                        data-bs-parent="#opdTimingsAccordion"
                      >
                        <div className="accordion-body">
                          <table className="table table-hover table-light">
                            <thead>
                              <tr>
                                <th scope="col">DAYS</th>
                                <th scope="col">TIMINGS</th>
                                <th scope="col">CENTRE</th>
                              </tr>
                            </thead>
                            <tbody>
                              {opd.schedule.map((schedule, idx) => (
                                <tr
                                  className="opd-table"
                                  key={`schedule-${index}-${idx}`}
                                >
                                  <th scope="row">{schedule.day}</th>
                                  <td>{schedule.time}</td>
                                  <td>{schedule.centre}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* gallery */}
            <div className="card">
              <div className="card-body">
                <h2 className="card-title widget-title mb-3">Media</h2>
                <div className="widget-content clearfix">
                  <ul className="image-list">
                    {gallery.map((g, index) => (
                      <li key={index}>
                        <figure>
                          <img
                            src={g.image}
                            alt={g.caption}
                            title={g.caption}
                            onClick={() => openModal(g.image)}
                          />
                        </figure>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* Modal */}
            <div
              className="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Image Preview
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p>{currentCaption}</p>
                    <img
                      src={currentImage}
                      alt={currentCaption}
                      title={currentCaption}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
            <div className="card">
              <div className="card about-style-two py-0">
                <div className="container card-body">
                  <div className="section-1">
                    <h3 className="sec-title">
                      Pulmonary & Respiratory Medicine at Ashwini Hospital
                    </h3>
                    <h5 className="fs-6 fw-bold text-primary">
                      About the Department
                    </h5>
                    <p>
                      The{" "}
                      <strong>
                        Department of Pulmonary & Respiratory Medicine
                      </strong>{" "}
                      at
                      <strong>Ashwini Group Of Hospitals, Odisha</strong>, is a
                      comprehensive center of excellence for diagnosing and
                      treating a wide spectrum of
                      <strong>Respiratory Diseases and Sleep Disorders</strong>.
                      Our in-house pulmonologists are well-trained in
                      evidence-based practices and equipped with advanced
                      diagnostic tools to offer{" "}
                      <strong>Personalized Care</strong> for both acute and
                      chronic respiratory illnesses.
                    </p>
                    <p>
                      We specialize in managing conditions like{" "}
                      <strong>
                        Asthma, COPD, Pneumonia, Interstitial Lung Disease, Lung
                        Cancer, Tuberculosis, and Sleep Apnea
                      </strong>
                      . Our state-of-the-art infrastructure allows for complete
                      pulmonary diagnostics, including{" "}
                      <strong>
                        Pulmonary Function Tests, Bronchoscopy, and Medical
                        Thoracoscopy
                      </strong>
                      .
                    </p>
                    <h4 className="sec-title">
                      Why Choose Ashwini Group for Pulmonary Medicine?
                    </h4>
                    <ul className="custom-style">
                      <li>
                        State-of-the-art lung diagnostics & interventional
                        pulmonology
                      </li>

                      <li>
                        Team of experienced pulmonologists and respiratory
                        therapists
                      </li>

                      <li>
                        Round-the-clock emergency and respiratory critical care
                        services
                      </li>

                      <li>
                        Evidence-based, individualized treatment protocols
                      </li>

                      <li>
                        Involvement in <strong>DNB Respiratory Medicine</strong>{" "}
                        training programs
                      </li>
                    </ul>
                    <h4 className="sec-title">Core Services</h4>
                    <ul className="custom-style">
                      <li>
                        <strong>Pulmonary Function Testing (PFT)</strong> –
                        Spirometry, lung volumes, and 6-minute walk tests
                      </li>

                      <li>
                        <strong>Diagnostic & Therapeutic Bronchoscopy</strong> –
                        Including endobronchial and transbronchial procedures
                      </li>

                      <li>
                        <strong>Medical Thoracoscopy</strong> – For pleurodesis
                        and pleural biopsies
                      </li>

                      <li>
                        <strong>FeNO Testing</strong> – Fractional Exhaled
                        Nitric Oxide for asthma monitoring
                      </li>

                      <li>
                        <strong>FOT</strong> – Forced Oscillation Technique for
                        airway resistance evaluation
                      </li>
                      <li>
                        <strong>Cryo Lung Biopsy</strong> – For precision
                        diagnosis of interstitial lung diseases
                      </li>
                      <li>
                        <strong>Sleep Studies</strong> – Comprehensive
                        evaluation for Sleep Apnea and Insomnia
                      </li>
                      <li>
                        <strong>Non-Invasive & Invasive Ventilation</strong> –
                        Advanced support for respiratory failure
                      </li>
                      <li>
                        <strong>
                          Biopsy of Pleura and Intra-thoracic Masses
                        </strong>
                      </li>
                    </ul>
                    {/* <div className="row text-center">
                      {[
                        { image: "", caption: "Pulmonary Function Testing" },
                        { image: "", caption: "Reconstructive Burn Surgery" },
                        { image: "", caption: "Complex Wound Coverage" },
                      ].map((facility, index) => (
                        <div key={index} className="col-md-4 mb-4 mt-2">
                          <div className="card h-100 shadow-sm">
                            <img
                              src={facility.image}
                              alt={facility.caption}
                              title={facility.caption}
                              className="img-fluid"
                              style={{ height: "200px", objectFit: "fill" }}
                              onClick={() =>
                                openModal(facility.image, facility.caption)
                              }
                            />
                            <div
                              className="p-2"
                              style={{ backgroundColor: "aliceblue" }}
                            >
                              <h5 className="fs-6 text-primary">
                                {facility.caption}
                              </h5>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div> */}
                    <div className="container card-body">
                      <h4 className="sec-title">Specialized Clinics</h4>
                      <ul className="custom-style">
                        <li>
                          <strong>Asthma Clinic </strong>– Holistic diagnosis,
                          treatment & counseling
                        </li>
                        <li>
                          <strong>COPD & Smoking Cessation Clinic</strong>
                        </li>
                        <li>
                          <strong>Pulmonary Rehabilitation</strong>
                        </li>
                        <li>
                          <strong>Sleep Medicine Unit</strong> –
                          Polysomnography, CPAP/BiPAP therapy
                        </li>
                        <li>
                          <strong>TB and Lung Cancer Services </strong>–
                          Diagnosis, biopsy, and treatment planning
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AAHPulmonology;
