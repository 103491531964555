import Gallery1 from "../../assets/gallery/g1.jpg";
import Gallery2 from "../../assets/gallery/g2.jpg";
import Gallery3 from "../../assets/gallery/g3.jpg";
import Gallery4 from "../../assets/gallery/g4.jpg";
import Gallery5 from "../../assets/gallery/g5.jpg";
import Gallery6 from "../../assets/gallery/g6.jpg";
import Gallery7 from "../../assets/gallery/g7.jpg";
import Gallery8 from "../../assets/gallery/g8.jpg";
import Gallery9 from "../../assets/gallery/g9.jpg";
import Gallery10 from "../../assets/gallery/g10.jpg";
import Gallery11 from "../../assets/gallery/g11.jpg";
import Gallery12 from "../../assets/gallery/g12.jpg";
import Gallery13 from "../../assets/gallery/g13.jpg";
import Gallery14 from "../../assets/gallery/g14.jpg";
import Gallery15 from "../../assets/gallery/g15.jpg";
import Gallery16 from "../../assets/gallery/g16.jpg";
import Gallery17 from "../../assets/gallery/g17.jpg";
import Gallery18 from "../../assets/gallery/g18.jpg";
import Gallery19 from "../../assets/gallery/g19.jpg";
import Gallery20 from "../../assets/gallery/g20.jpg";
import Gallery21 from "../../assets/gallery/g21.jpg";
import Gallery22 from "../../assets/gallery/g22.jpg";
import Gallery23 from "../../assets/gallery/g23.jpg";
import Gallery24 from "../../assets/gallery/g24.jpg";
import Gallery25 from "../../assets/gallery/g25.jpg";
import Gallery26 from "../../assets/gallery/g26.jpg";
import Gallery27 from "../../assets/gallery/g27.jpg";
import Gallery28 from "../../assets/gallery/g28.jpg";
import Gallery29 from "../../assets/gallery/g29.jpg";
import Gallery31 from "../../assets/gallery/g31.jpg";
import Gallery46 from "../../assets/gallery/g46.jpg";
import Gallery49 from "../../assets/gallery/g49.jpg";
import Gallery50 from "../../assets/gallery/g50.jpg";
import Gallery63 from "../../assets/gallery/g63.jpg";
import Gallery70 from "../../assets/gallery/g70.jpg";
import Gallery71 from "../../assets/gallery/g81.jpg";
import Gallery72 from "../../assets/gallery/g85.jpg";
import Gallery73 from "../../assets/gallery/g105.jpg";
import Gallery74 from "../../assets/gallery/g121.jpg";
import NA1 from "../../assets/news and article/news1.jpg";
import NA2 from "../../assets/news and article/news2.jpg";
import NA3 from "../../assets/news and article/news3.jpg";
import NA4 from "../../assets/news and article/news4.jpg";
import NA5 from "../../assets/news and article/news5.jpg";
import NA6 from "../../assets/news and article/news6.jpg";
import NA7 from "../../assets/news and article/news7.jpg";
import NA8 from "../../assets/news and article/news8.jpg";
import NA9 from "../../assets/news and article/news9.png";
import NA10 from "../../assets/news and article/news10.png";
import NA11 from "../../assets/news and article/news11.mp4";
import NA12 from "../../assets/news and article/news12.jpg";
import NA13 from "../../assets/news and article/news13.jpg";
import NA14 from "../../assets/news and article/news14.jpg";
import NA15 from "../../assets/news and article/news15.jpg";
import Testimonials1 from "../../assets/testimonials/t1.mp4";
import Testimonials2 from "../../assets/testimonials/t2.mp4";
import Testimonials3 from "../../assets/testimonials/t3.mp4";
import Blog1 from "../../assets/Blog Assets/Blog1.jpg";
import Blog2 from "../../assets/Blog Assets/Blog2.png";

const TabItems = [
    // Gallery
    { id: 74, media: Gallery74, alt: "Gallery", category: 'gallery', type: 'image', text: "19th Foundation Day",link:'/Events/19th-Foundation-Day' },
    { id: 73, media: Gallery73, alt: "Gallery", category: 'gallery', type: 'image', text: "Maternal Health Awareness Day",link:'/Events/Maternal-Health-Awareness-Day' },
    { id: 72, media: Gallery72, alt: "Gallery", category: 'gallery', type: 'image', text: "Cryotherapy Workshop",link:'/Events/Cryotherapy-Workshop-at-Ashwini-Group-of-Hospitals' },
    { id: 71, media: Gallery71, alt: "Gallery", category: 'gallery', type: 'image', text: "Stroke Day Walkathon",link:'/Events/Walkathon-Yoga-Session-for-Stroke-Awareness' },
    { id: 70, media: Gallery70, alt: "Gallery", category: 'gallery', type: 'image', text: "Joint Reconstruction Workshop",link:'/Events/Joint-Reconstruction-Workshop' },
    { id: 65, media: Gallery63, alt: "Gallery", category: 'gallery', type: 'image', text: "ARDS-Update Workshop",link:'/Events/ARDS-Update-Workshop'},
    { id: 50, media: Gallery50, alt: "Gallery", category: 'gallery', type: 'image', text: "78th Independence Day",link:'/Events/78th-Independence-Day' },
    { id: 49, media: Gallery49, alt: "Gallery", category: 'gallery', type: 'image', text: "Doctor's Day",link:'/Events/Doctors-Day' },
    { id: 31, media: Gallery31, alt: "Gallery", category: 'gallery', type: 'image', text: "Neuro-Radiology for Clinicians",link:'/Events/Neuro-Radiology-for-Clinicians' },
    { id: 46, media: Gallery46, alt: "Gallery", category: 'gallery', type: 'image', text: "Neuro Immunology Workshop",link:'/Events/Neuro-Immunology-Workshop' },
    { id: 1, media: Gallery1, alt: "Gallery", category: 'gallery', type: 'image', text: "Ashwini Group of Hospitals Event" },
    { id: 2, media: Gallery2, alt: "Gallery", category: 'gallery', type: 'image', text: "Emergency Ward" },
    { id: 3, media: Gallery3, alt: "Gallery", category: 'gallery', type: 'image', text: "Blood Donation" },
    { id: 4, media: Gallery4, alt: "Gallery", category: 'gallery', type: 'image', text: "Ashwini Group of Hospitals Ward" },
    { id: 5, media: Gallery5, alt: "Gallery", category: 'gallery', type: 'image', text: "Ashwini Wellness Event" },
    { id: 6, media: Gallery6, alt: "Gallery", category: 'gallery', type: 'image', text: "Ashwini Wellness Event" },
    { id: 7, media: Gallery7, alt: "Gallery", category: 'gallery', type: 'image', text: "Ashwini Wellness Event" },
    { id: 8, media: Gallery8, alt: "Gallery", category: 'gallery', type: 'image', text: "World Tuberculosis Day" },
    { id: 9, media: Gallery9, alt: "Gallery", category: 'gallery', type: 'image', text: "Meeting in NABH cell/DNB office" },
    { id: 10, media: Gallery10, alt: "Gallery", category: 'gallery', type: 'image', text: "Classroom" },
    { id: 11, media: Gallery11, alt: "Gallery", category: 'gallery', type: 'image', text: "Classroom" },
    { id: 12, media: Gallery12, alt: "Gallery", category: 'gallery', type: 'image', text: "Nursing Desk" },
    { id: 13, media: Gallery13, alt: "Gallery", category: 'gallery', type: 'image', text: "Neuro HDU" },
    { id: 14, media: Gallery14, alt: "Gallery", category: 'gallery', type: 'image', text: "Neuro HDU" },
    { id: 15, media: Gallery15, alt: "Gallery", category: 'gallery', type: 'image', text: "ICU" },
    { id: 16, media: Gallery16, alt: "Gallery", category: 'gallery', type: 'image', text: "ICU" },
    { id: 17, media: Gallery17, alt: "Gallery", category: 'gallery', type: 'image', text: "OPD Reception" },
    { id: 18, media: Gallery18, alt: "Gallery", category: 'gallery', type: 'image', text: "CT scan" },
    { id: 19, media: Gallery19, alt: "Gallery", category: 'gallery', type: 'image', text: "Ambulance Facility" },
    { id: 20, media: Gallery20, alt: "Gallery", category: 'gallery', type: 'image', text: "Hospital Pharmacy" },
    { id: 21, media: Gallery21, alt: "Gallery", category: 'gallery', type: 'image', text: "Ashwini Trauma Centre Reception" },
    { id: 22, media: Gallery22, alt: "Gallery", category: 'gallery', type: 'image', text: "Cafeteria" },
    { id: 23, media: Gallery23, alt: "Gallery", category: 'gallery', type: 'image', text: "SmileTrain" },
    { id: 24, media: Gallery24, alt: "Gallery", category: 'gallery', type: 'image', text: "Emergency Ward" },
    { id: 25, media: Gallery25, alt: "Gallery", category: 'gallery', type: 'image', text: "Ashwini Group of Hospitals Seminar Hall" },
    { id: 26, media: Gallery26, alt: "Gallery", category: 'gallery', type: 'image', text: "Blood Camp" },
    { id: 27, media: Gallery27, alt: "Gallery", category: 'gallery', type: 'image', text: "Laboratory Services" },
    { id: 28, media: Gallery28, alt: "Gallery", category: 'gallery', type: 'image', text: "Finance Department" },
    { id: 29, media: Gallery29, alt: "Gallery", category: 'gallery', type: 'image', text: "MRI scan" },

    // News and Articles
    { media: NA15, alt: "News&Articles", category: 'newsarticles', type: 'image', text: "World Smile Day" },
    { media: NA14, alt: "News&Articles", category: 'newsarticles', type: 'image', text: "World Smile Day" },
    { media: NA13, alt: "News&Articles", category: 'newsarticles', type: 'image', text: "World Smile Day" },
    { media: NA12, alt: "News&Articles", category: 'newsarticles', type: 'image', text: "Heart Day Walkathon" },
    { media: NA1, alt: "News&Articles", category: 'newsarticles', type: 'image', text: "Golden hour concept in Trauma Care" },
    { media: NA2, alt: "News&Articles", category: 'newsarticles', type: 'image', text: "Plastic Surgeon Article" },
    { media: NA3, alt: "News&Articles", category: 'newsarticles', type: 'image', text: "Surgery of the Hands" },
    { media: NA4, alt: "News&Articles", category: 'newsarticles', type: 'image', text: "Mother-Daughter Duo in Healthsector" },
    { media: NA5, alt: "News&Articles", category: 'newsarticles', type: 'image', text: "Visionary Entreprenuer Article" },
    { media: NA6, alt: "News&Articles", category: 'newsarticles', type: 'image', text: "Journer of AGH Director" },
    { media: NA7, alt: "News&Articles", category: 'newsarticles', type: 'image', text: "Awarded in Covid" },
    { media: NA8, alt: "News&Articles", category: 'newsarticles', type: 'image', text: "Women Architects of New India" },
    { media: NA9, alt: "News&Articles", category: 'newsarticles', type: 'image', text: "Vaccination Hesitancy" },
    { media: NA10, alt: "News&Articles", category: 'newsarticles', type: 'image', text: "Pioneering the Change" },
    { media: NA11, alt: "News&Articles", category: 'newsarticles', type: 'video' },

    // Testimonials
    { media: Testimonials1, alt: "Testimonials", category: 'testimonials', type: 'video' },
    { media: Testimonials2, alt: "Testimonials", category: 'testimonials', type: 'video' },
    { media: Testimonials3, alt: "Testimonials", category: 'testimonials', type: 'video' },

    // Blogs
    { media: Blog1, alt: "Blogs", category: 'blogs', type: 'blogs', link:'/Blog/NPWT' },
    { media: Blog2, alt: "Blogs", category: 'blogs', type: 'blogs', link:'/Blog/Robotic' },
    // { media: Gallery2, alt: "Blogs", category: 'blogs', type: 'blogs', link:'/Departments' },
]

export default TabItems;