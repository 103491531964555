import React, { useState, useEffect } from "react";
import "../Academic.css";
import Navbar from "../../Navbar/Navbar";
import neurologyImg from "../../../assets/DrNB Neurology.jpg";
import neurosurgeryImg from "../../../assets/DrNB Neurosurgery.jpg";
import criticalCareImg from "../../../assets/DrNB Critical Care.jpg";
import plasticSurgeryImg from "../../../assets/DrNB Plastic Surgery.jpg";

function DrNB() {
  const [activeTab, setActiveTab] = useState("Courses");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const programs = [
    {
      name: "DrNB Neurology",
      image: neurologyImg,
      path: "/Academic/DrNB/drnb-neurology",
    },
    {
      name: "DrNB Neurosurgery",
      image: neurosurgeryImg,
      path: "/Academic/DrNB/drnb-neuro-surgery",
    },
    {
      name: "DrNB Critical Care",
      image: criticalCareImg,
      path: "/Academic/DrNB/drnb-Critical-Care",
    },
    {
      name: "DrNB Plastic Surgery",
      image: plasticSurgeryImg,
      path: "/Academic/DrNB/drnb-Plastic-Surgery",
    },
  ];

  return (
    <>
      <Navbar />

      {/* DrNB Message */}
      <div className="card course-title">
        <div className="container card-body">
          <div className="sec-title">
            <h1>DrNB Course Details</h1>
          </div>
          <div className="my-text-box">
            <p>
              <b>Ashwini Group of Hospitals</b>, accredited by the{" "}
              <b>
                National Board of Examinations in Medical Sciences (NBEMS), New
                Delhi
              </b>
              , offers postgraduate medical training through{" "}
              <b>DrNB programs</b> in various specialties. Since 2023, our
              institution has been dedicated to providing high-quality education
              and hands-on clinical exposure to aspiring medical professionals.
            </p>
            <p>
              With three hospitals and a total bed strength of <b>500+</b>, we
              ensure comprehensive training across multiple disciplines. Our
              state-of-the-art library is stocked with the latest reference
              books and journals, complemented by full-time internet access and
              digital learning through tablets. Additionally, hostel facilities
              are available based on availability, and <b>stipends</b> are
              provided as per State Government Guidelines.
            </p>
          </div>
        </div>
      </div>
      <section className="programs-grid-section">
        <div className="container">
          <div className="my-sec-title">
            <h2>Programs Offered</h2>
          </div>
          <div className="grid-container">
            {programs.map((program, index) => (
              <div key={index} className="grid-item">
                <a href={program.path}>
                  <img
                    src={program.image}
                    alt={program.name}
                    title={program.name}
                    className="program-image"
                    loading="lazy"
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* DrNB structure */}
      <div className="course-box mb-3">
        <div className="container">
          <div className="sec-title">
            <h1>DrNB Structure</h1>
          </div>
          <ul className="nav nav-tabs">
            <li className="nav-item tab_option">
              <button
                className={`nav-link ${
                  activeTab === "Courses" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Courses")}
              >
                Courses
              </button>
            </li>
            <li className="nav-item tab_option">
              <button
                className={`nav-link ${
                  activeTab === "Fee Structure" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Fee Structure")}
              >
                Fee Structure
              </button>
            </li>
            <li className="nav-item tab_option">
              <button
                className={`nav-link ${
                  activeTab === "Stipend" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Stipend")}
              >
                Stipend
              </button>
            </li>
            <li className="nav-item tab_option">
              <button
                className={`nav-link ${
                  activeTab === "Required Documents" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Required Documents")}
              >
                Required Documents
              </button>
            </li>
          </ul>

          <div className="tab-content">
            {activeTab === "Courses" && (
              <div>
                <table className="table table-bordered">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">Site</th>
                      <th scope="col">Speciality</th>
                      <th scope="col">Course</th>
                      <th scope="col">No. of seats</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Ashwini Hospital</td>
                      <td>Neurology</td>
                      <td>Post PG</td>
                      <td>3 seats</td>
                    </tr>
                    <tr>
                      <td>Ashwini Hospital</td>
                      <td>Neurosurgery</td>
                      <td>Post PG</td>
                      <td>2 seats</td>
                    </tr>
                    <tr>
                      <td>Ashwini Hospital</td>
                      <td>
                        Critical Care{" "}
                        <span className="badge bg-danger">New</span>
                      </td>
                      <td>Post PG</td>
                      <td>2 seats</td>
                    </tr>
                    <tr>
                      <td>Ashwini Trauma Centre</td>
                      <td>Plastic Surgery</td>
                      <td>Post PG</td>
                      <td>2 seats</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            {activeTab === "Fee Structure" && (
              <div className="fees">
                <ul>
                  <li>
                    <span>1st year</span> : Rs 1,25,000/- (Per Year)
                  </li>
                  <li>
                    <span>2nd year</span> : Rs 1,25,000/- (Per Year)
                  </li>
                  <li>
                    <span>3rd year</span> : Rs 1,25,000/- (Per Year)
                  </li>
                </ul>
              </div>
            )}

            {activeTab === "Stipend" && (
              <div className="stipend">
                <ul>
                  <li>
                    <span>1st year</span> : Rs. 85,000/-
                  </li>
                  <li>
                    <span>2nd year</span> : Rs. 90,000/-.
                  </li>
                  <li>
                    <span>3rd year</span> : Rs. 95,000/-
                  </li>
                </ul>
              </div>
            )}

            {activeTab === "Required Documents" && (
              <div className="documents">
                <ul>
                  <li>DNB/DrNB Joining Report</li>
                  <li>DNB/DrNB fee submission 1st year receipt</li>
                  <li>MCC Provisional Allotment Letter</li>
                  <li>NEET Score Card</li>
                  <li>Provisional admission letter</li>
                  <li>NEET Admit Card</li>
                  <li>Candidate medical registration latest certificate</li>
                  <li>Internship Certificate</li>
                  <li>MBBS Provisional Certificate</li>
                  <li>MBBS All Marksheets</li>
                  <li>10th Pass Certificate</li>
                  <li>10th Marksheets</li>
                  <li>Post MBBS Certificate Marksheets</li>
                  <li>Aadhar Card</li>
                  <li>PAN Card</li>
                  <li>Cancelled Cheque</li>
                  <li>Relieved Order(if any)</li>
                  <li>Transfer Certificate or College Leaving Certificate</li>
                  <li>Character Certificate</li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default DrNB;
