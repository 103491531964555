import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";
import NavbarLocation from "../../Navbar/NavbarLocation";
import "./DrNBCourses.css";
import CriticalCareImg from "../../../assets/DrNB Critical Care.jpg";
import SEO from "../../SEO/SEO";
import emailjs from "@emailjs/browser";
import Img1 from "../../../assets/doctors/samir_sahu.jpg";
import Img2 from "../../../assets/doctors/soumyaraj_ghosh.png";
import Img3 from "../../../assets/doctors/chinmaya_sahu.png";
import Img4 from "../../../assets/doctors/manisha_mishra.png";
import Img5 from "../../../assets/doctors/satya_prakash_mohanty.png";
import Img6 from "../../../assets/doctors/bithika_jana.png";
import Img7 from "../../../assets/doctors/swaleha.png";
import Img8 from "../../../assets/doctors/raksha-baheti.png";
import Img9 from "../../../assets/doctors/Dr.Sunita-Dutta.png";
import Img10 from "../../../assets/doctors/Dr.Dibyajit-Sabath.png";

function DrNBCriticalCare() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [status, setStatus] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.toLowerCase();

  let locationFilter = null;
  let basePath = "/Criticalcare";
  if (path.includes("ashwinihospitalcuttack")) {
    locationFilter = "Ashwini Hospital";
    basePath = "/AshwiniHospitalCuttack/Criticalcare";
  } else if (path.includes("adityaashwinihospital")) {
    locationFilter = "Aditya Ashwini Hospital";
  } else if (path.includes("ashwinitraumacentre")) {
    locationFilter = "Ashwini Trauma Centre";
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_tj5tyam",
        "template_1tpu44e",
        formData,
        "egxcYC6EeJdXDm0et"
      )
      .then(
        (response) => {
          console.log("Email sent successfully!", response);
          setStatus("Message sent successfully!");
          setFormData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          });
        },
        (error) => {
          console.error("Failed to send email:", error);
          setStatus("Failed to send message. Please try again.");
        }
      );
  };

  const slides = [
    {
      image: Img1,
      name: "Dr. Samir Sahu",
      qualification: "MD(Medicine),FICCM",
      location: "Ashwini Hospital",
    },
    {
      image: Img2,
      name: "Dr. Soumyaraj Ghosh",
      qualification: "MD(Anesthesiology),IDCCM",
      location: "Ashwini Hospital",
    },
    {
      image: Img3,
      name: "Dr. Chinmaya Sahu",
      qualification: "MD(Anesthesiology)",
      location: "Ashwini Hospital",
    },
    {
      image: Img4,
      name: "Dr. Manisha Mishra",
      qualification: "Physiotherapist",
      location: "Ashwini Hospital",
    },
    {
      image: Img5,
      name: "Dr. Satya Prakash Mohanty",
      qualification: "Physiotherapist",
      location: "Ashwini Hospital",
    },
    {
      image: Img10,
      name: "Dr. Dibyajit Sabath",
      qualification: "Physiotherapist",
      location: "Ashwini Hospital",
    },
    {
      image: Img6,
      name: "Dt. Bithika Jana",
      qualification: "M.Sc(Nutrition & Dietetics)",
      location: "Ashwini Hospital",
    },
    {
      image: Img7,
      name: "Dt. Swaleha Shirin",
      qualification: "Nutrition & Dietetics",
      location: "Ashwini Trauma Centre",
    },
    {
      image: Img8,
      name: "Dt. Raksha Baheti",
      qualification: "Nutrition & Dietetics",
      location: "Aditya Ashwini Hospital",
    },
    {
      image: Img9,
      name: "Dr. Sunita Dutta",
      qualification: "Speech Therapist",
      location: "Ashwini Hospital",
    },
  ];
  const filteredDoctors = locationFilter
    ? slides.filter((doc) => doc.location === locationFilter)
    : slides;
  return (
    <>
      {locationFilter ? <NavbarLocation /> : <Navbar />}
      <SEO
        title="DrNB Critical Care at Ashwini Hospital | Critical Care Super Speciality Training in Odisha"
        description="Advance your career in Critical Care Medicine with the DrNB program at Ashwini Hospital, Odisha. Learn from experienced faculty, gain hands-on exposure in a multi-specialty ICU setup, and build a strong foundation in intensive care."
        keywords="DrNB Critical Care India, DrNB Critical Care Ashwini Hospital, Critical Care Super Speciality Odisha, Best DrNB Critical Care Program India, Critical Care Medicine Training Odisha, DrNB Courses Cuttack, NBE Accredited Critical Care Training, Ashwini Hospital DNB, ICU Training Program India, Neuro ICU Training, Cardiac ICU DrNB Program, Advanced Critical Care Fellowship India

"
      />
      <div className="drnb-neurology">
        {/* Banner Section */}
        <button
          className="btn btn-danger position-absolute"
          onClick={() => navigate(basePath)}
        >
          Critical Care Department
        </button>
        <section className="banner">
          <h1>DrNB CRITICAL CARE</h1>
          <p>
            Accredited by the{" "}
            <strong>
              National Board of Examinations in Medical Sciences, New Delhi
            </strong>
            , Ashwini Group of Hospitals proudly offers{" "}
            <span className="text-warning fw-bold">2 Seats</span> for Post
            Graduate training in Critical Care.
          </p>
        </section>

        {/* Description */}
        <section className="drnb-description mt-2">
          <p>
            Ashwini Group of Hospitals proudly offers the{" "}
            <strong>DrNB Critical Care</strong> program, accredited by the{" "}
            <strong>
              National Board of Examinations in Medical Sciences (NBEMS), New
              Delhi
            </strong>
            . The program is designed to train doctors in managing critically
            ill patients using evidence-based, protocol-driven approaches under
            the supervision of experienced faculty and in a modern ICU
            environment equipped with the latest life-support systems.
          </p>
        </section>
        <section className="about-hospital mt-2">
          <h2 className="sec-title">About Ashwini Hospital</h2>
          <p>
            <strong>Ashwini Hospital</strong> is one of the top{" "}
            <strong>Multispecialty Hospitals in Odisha</strong>, offering
            high-quality care across various specialties. The Critical Care
            department is equipped with Level 3 ICUs, invasive and non-invasive
            ventilation systems, bedside ultrasound, ABG machines, central
            monitoring, and round-the-clock intensivist-led teams. With over{" "}
            <strong>500+</strong> beds, we provide a conducive learning
            environment for future intensivists.
          </p>
        </section>
        {/* Admission Procedure */}
        <section className="admission">
          <h2 className="sec-title">Admission Procedure</h2>
          {/* How to Apply */}
          <section className="apply">
            <h5 className="text-decoration-underline text-primary">
              How to Apply
            </h5>
            <p>
              Candidates interested in enrolling for the DrNB Critical Care
              program must qualify for the{" "}
              <strong>
                NEET-SS (National Eligibility cum Entrance Test – Super
                Specialty), conducted by NBEMS
              </strong>
              . Admission is based on NEET-SS rank, followed by the NBEMS
              counseling process. Shortlisted candidates will be guided through
              the documentation and admission formalities.
            </p>
          </section>
          <ul className="list-unstyled">
            <li>
              ✔️ <strong className="text-success">Step 1:</strong> Appear for{" "}
              <strong>NEET-SS</strong> conducted by NBEMS and obtain a
              Qualifying Rank.
            </li>
            <li>
              ✔️ <strong className="text-success">Step 2:</strong> Choose
              Ashwini Hospital for <strong>DrNB Critical Care</strong> during
              the <b>Counseling Process.</b>
            </li>
            <li>
              ✔️ <strong className="text-success">Step 3:</strong> Complete the
              required document verification & formalities.
            </li>
            <li>
              ✔️ <strong className="text-success">Step 4:</strong> Admission
              confirmation and course commencement.
            </li>
          </ul>
          <p className="lh-1">
            <span className="text-title">
              &#128204;For Admissions, refer to:{" "}
              <a
                href="https://nbe.edu.in"
                target="_blank"
                className="text-primary text-decoration-none"
                rel="noopener noreferrer"
              >
                NBEMS Official Website
              </a>
            </span>
          </p>
        </section>
        {/* Course Duration */}
        <section className="course-duration">
          <h5 className="text-decoration-underline text-primary">
            Course Duration
          </h5>
          <p>
            The DrNB Critical Care program is a{" "}
            <b>3-Year full-time residency</b> course focused on advanced
            intensive care, including mechanical ventilation, hemodynamic
            monitoring, renal replacement therapy, sepsis management, and trauma
            ICU care.
          </p>
        </section>
        {/* Eligibility Criteria */}
        <section className="eligibility">
          <h5 className="text-decoration-underline text-primary">
            Eligibility Criteria
          </h5>
          <p>
            Candidates must possess a postgraduate degree such as{" "}
            <strong>
              MD/DNB in General Medicine, Anesthesia, Pulmonary Medicine
            </strong>
            , or equivalent. They should also qualify in the NEET-SS examination
            conducted by NBEMS and participate in the centralized counseling.{" "}
            <strong>
              Seat allocation is based strictly on merit and choice.
            </strong>
            Seat allocation for DrNB Critical Care is done purely on a
            merit-cum-choice basis.
          </p>
        </section>

        {/* Opportunities Section */}
        <section className="opportunities">
          <h2 className="sec-title">Opportunities</h2>
          <p>
            Candidates who obtain the <strong>DrNB Qualification</strong> after
            successfully completing the DrNB examination are eligible for{" "}
            <strong>specialist positions</strong> in hospitals as well as{" "}
            <strong>faculty roles in teaching institutions.</strong> They can be
            appointed as{" "}
            <strong>
              faculty members, teachers, assistant professors, or lecturers in
              medical training institutions.
            </strong>
            The accreditation of institutes and the selection of residents are
            conducted strictly in accordance with the{" "}
            <strong>guidelines and regulations set by NBEMS</strong>, which are
            updated periodically.
          </p>
        </section>

        {/* Registration Form */}
        <section className="registration d-flex justify-content-center">
          {/* Image Section */}
          <div className="promo-image">
            <img
              src={CriticalCareImg}
              alt="DrNB Critical Care Program"
              title="DrNB Critical Care Program Details"
              loading="lazy"
            />
          </div>
          <div className="admission-form-container mt-4">
            <h4 className="sec-title">Enroll in DrNB Critical Care Program</h4>
            {status && <p className="status-message">{status}</p>}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Phone</label>
                <input
                  type="tel"
                  name="phone"
                  placeholder="Your Number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Subject</label>
                <input
                  type="text"
                  name="subject"
                  placeholder="Your Subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Message</label>
                <textarea
                  name="message"
                  placeholder="Your Message Here"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>

              <button type="submit" className="submit-btn">
                Submit
              </button>
            </form>
          </div>
        </section>

        {/* Neurosurgery Team */}
        <section className="team">
          <h2 className="sec-title">Our Critical Care Team</h2>
          <div className="row row-cols-1 row-cols-md-4 g-4 ">
            {filteredDoctors.length > 0
              ? filteredDoctors.map((doctor, index) => (
                  <div className="col-lg-2 col-md-4" key={index}>
                    <a
                      className="doc-name"
                      href={`/Doctors/${doctor.name
                        .replace(/\s+/g, "")
                        .toLowerCase()}`}
                    >
                      <div className="card h-100 doctor_img my-doc">
                        <img
                          src={doctor.image}
                          alt={`${doctor.name} Img`}
                          title={doctor.name}
                        />
                        <div className="drnb-doc-text mt-2">
                          <h3>{doctor.name}</h3>
                          <p>{doctor.qualification}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                ))
              : ""}
          </div>
        </section>
      </div>
    </>
  );
}
export default DrNBCriticalCare;
