import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";
import NavbarLocation from "../../Navbar/NavbarLocation";
import "./DrNBCourses.css";
import PlasticSurgeryImg from "../../../assets/DrNB Plastic Surgery.jpg";
import SEO from "../../SEO/SEO";
import emailjs from "@emailjs/browser";
import Img1 from "../../../assets/doctors/subrat_jena.png";
import Img2 from "../../../assets/doctors/ruchi_gupta.png";
import Img3 from "../../../assets/doctors/user.png";
import Img4 from "../../../assets/doctors/manisha_mishra.png";
import Img5 from "../../../assets/doctors/satya_prakash_mohanty.png";
import Img6 from "../../../assets/doctors/bithika_jana.png";
import Img7 from "../../../assets/doctors/swaleha.png";
import Img8 from "../../../assets/doctors/raksha-baheti.png";
import Img9 from "../../../assets/doctors/Dr.Sunita-Dutta.png";
import Img10 from "../../../assets/doctors/sibani_das.jpg";
import Img11 from "../../../assets/doctors/Dr.Dibyajit-Sabath.png";

function DrNBPlasticSurgery() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [status, setStatus] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.toLowerCase();

  let locationFilter = null;
  let basePath = "/PlasticSurgery";
  if (path.includes("ashwinihospitalcuttack")) {
    locationFilter = "Ashwini Hospital";
  } else if (path.includes("adityaashwinihospital")) {
    locationFilter = "Aditya Ashwini Hospital";
  } else if (path.includes("ashwinitraumacentre")) {
    locationFilter = "Ashwini Trauma Centre";
    basePath = "/AshwiniTraumaCentre/PlasticSurgery";
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_tj5tyam",
        "template_1tpu44e",
        formData,
        "egxcYC6EeJdXDm0et"
      )
      .then(
        (response) => {
          console.log("Email sent successfully!", response);
          setStatus("Message sent successfully!");
          setFormData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          });
        },
        (error) => {
          console.error("Failed to send email:", error);
          setStatus("Failed to send message. Please try again.");
        }
      );
  };

  const slides = [
    {
      image: Img1,
      name: "Dr. Subrat Kumar Jena",
      qualification: "M.S(Ortho), M.Ch(Plastic Surgery)",
      location: "Ashwini Trauma Centre",
    },
    {
      image: Img2,
      name: "Dr. Ruchi Gupta",
      qualification: "M.S(General Surgeon), M.Ch(Plastic Surgery)",
      location: "Ashwini Trauma Centre",
    },
    {
      image: Img3,
      name: "Dr. Sudhakar Jena",
      qualification: "M.Ch(Plastic Surgery)",
      location: "Ashwini Trauma Centre",
    },
    {
      image: Img10,
      name: "Dr. Sibani Das",
      qualification: "MDS(Oral and Maxillofacial Surgery)",
      location: "Ashwini Trauma Centre",
    },
    {
      image: Img4,
      name: "Dr. Manisha Mishra",
      qualification: "Physiotherapist",
      location: "Ashwini Hospital",
    },
    {
      image: Img5,
      name: "Dr. Satya Prakash Mohanty",
      qualification: "Physiotherapist",
      location: "Ashwini Hospital",
    },
    {
      image: Img11,
      name: "Dr. Dibyajit Sabath",
      qualification: "Physiotherapist",
      location: "Ashwini Hospital",
    },
    {
      image: Img6,
      name: "Dt. Bithika Jana",
      qualification: "M.Sc(Nutrition & Dietetics)",
      location: "Ashwini Hospital",
    },
    {
      image: Img7,
      name: "Dt. Swaleha Shirin",
      qualification: "Nutrition & Dietetics",
      location: "Ashwini Trauma Centre",
    },
    {
      image: Img8,
      name: "Dt. Raksha Baheti",
      qualification: "Nutrition & Dietetics",
      location: "Aditya Ashwini Hospital",
    },
    {
      image: Img9,
      name: "Dr. Sunita Dutta",
      qualification: "Speech Therapist",
      location: "Ashwini Hospital",
    },
  ];
  const filteredDoctors = locationFilter
    ? slides.filter((doc) => doc.location === locationFilter)
    : slides;
  return (
    <>
      {locationFilter ? <NavbarLocation /> : <Navbar />}
      <SEO
        title="DrNB Plastic Surgery Program at Ashwini Hospital | Odisha, India"
        description="Join the DrNB Plastic Surgery training at Ashwini Hospital, Odisha — a center of excellence offering comprehensive exposure in reconstructive, cosmetic, burn, and micro-surgery with expert mentorship and advanced facilities."
        keywords="DrNB Plastic Surgery, Plastic Surgery Training India, Reconstructive Surgery Course, Cosmetic Surgery Fellowship, Burn Surgery Training, Plastic Surgery Odisha, Plastic Surgery Residency India, DrNB Program in Odisha, Ashwini Hospital Plastic Surgery, NBE Accredited Plastic Surgery Hospital

"
      />
      <div className="drnb-neurology">
        {/* Banner Section */}
        <button
          className="btn btn-danger position-absolute"
          onClick={() => navigate(basePath)}
        >
          Plastic Surgery Department
        </button>
        <section className="banner">
          <h1>DrNB PLASTIC SURGERY</h1>
          <p>
            Accredited by the{" "}
            <strong>
              National Board of Examinations in Medical Sciences, New Delhi
            </strong>
            , Ashwini Group of Hospitals proudly offers{" "}
            <span className="text-warning fw-bold">2 Seats</span> for Post
            Graduate training in Plastic Surgery.
          </p>
        </section>

        {/* Description */}
        <section className="drnb-description mt-2">
          <p>
            Ashwini Group of Hospitals proudly offers the{" "}
            <strong>DrNB Plastic Surgery</strong> program, accredited by the{" "}
            <strong>
              National Board of Examinations in Medical Sciences (NBEMS), New
              Delhi
            </strong>
            . This super-specialty program is designed to provide advanced
            hands-on training in reconstructive, cosmetic, burn, and
            microsurgery techniques. With experienced mentors and high surgical
            volumes, the program ensures comprehensive clinical exposure and
            academic development.
          </p>
        </section>
        <section className="about-hospital mt-2">
          <h2 className="sec-title">About Ashwini Hospital</h2>
          <p>
            <strong>Ashwini Hospital</strong> is one of the top{" "}
            <strong>Multispecialty Hospitals in Odisha</strong>, offering
            high-quality care across various specialties. The Critical Care
            department is equipped with High Level ICUs, invasive and
            non-invasive ventilation systems, bedside ultrasound, ABG machines,
            central monitoring, and round-the-clock intensivist-led teams. With
            over <strong>500+</strong> beds, we provide a conducive learning
            environment for future intensivists.
          </p>
        </section>
        {/* Admission Procedure */}
        <section className="admission">
          <h2 className="sec-title">Admission Procedure</h2>
          {/* How to Apply */}
          <section className="apply">
            <h5 className="text-decoration-underline text-primary">
              How to Apply
            </h5>
            <p>
              Candidates interested in enrolling for the DrNB Plastic Surgery
              program must qualify for the{" "}
              <strong>
                NEET-SS (National Eligibility cum Entrance Test – Super
                Specialty), conducted by NBEMS
              </strong>
              . Admission is based on NEET-SS rank, followed by the NBEMS
              counseling process. Shortlisted candidates will be guided through
              the documentation and admission formalities.
            </p>
          </section>
          <ul className="list-unstyled">
            <li>
              ✔️ <strong className="text-success">Step 1:</strong> Appear for{" "}
              <strong>NEET-SS</strong> conducted by NBEMS and obtain a
              Qualifying Rank.
            </li>
            <li>
              ✔️ <strong className="text-success">Step 2:</strong> Choose
              Ashwini Hospital for <strong>DrNB Plastic Surgery</strong> during
              the <b>Counseling Process.</b>
            </li>
            <li>
              ✔️ <strong className="text-success">Step 3:</strong> Complete the
              required document verification & formalities.
            </li>
            <li>
              ✔️ <strong className="text-success">Step 4:</strong> Admission
              confirmation and course commencement.
            </li>
          </ul>
          <p className="lh-1">
            <span className="text-title">
              &#128204;For Admissions, refer to:{" "}
              <a
                href="https://nbe.edu.in"
                target="_blank"
                className="text-primary text-decoration-none"
                rel="noopener noreferrer"
              >
                NBEMS Official Website
              </a>
            </span>
          </p>
        </section>
        {/* Course Duration */}
        <section className="course-duration">
          <h5 className="text-decoration-underline text-primary">
            Course Duration
          </h5>
          <p>
            The DrNB Plastic Surgery program is a <strong>3-year</strong>{" "}
            intensive training course covering all facets of plastic and
            reconstructive surgery— including cosmetic procedures, trauma
            reconstruction, burns, congenital deformities, and microvascular
            surgery.
          </p>
        </section>
        {/* Eligibility Criteria */}
        <section className="eligibility">
          <h5 className="text-decoration-underline text-primary">
            Eligibility Criteria
          </h5>
          <p>
            Candidates must possess a postgraduate degree such as{" "}
            <strong>MS/DNB in General Surgery</strong>, or equivalent. They
            should also qualify in the NEET-SS examination conducted by NBEMS
            and participate in the centralized counseling.{" "}
            <strong>
              Seat allocation is based strictly on merit and choice.
            </strong>
            Seat allocation for DrNB Plastic Surgery is done purely on a
            merit-cum-choice basis.
          </p>
        </section>

        {/* Opportunities Section */}
        <section className="opportunities">
          <h2 className="sec-title">Opportunities</h2>
          <p>
            Candidates who obtain the <strong>DrNB Qualification</strong> after
            successfully completing the DrNB examination are eligible for{" "}
            <strong>specialist positions</strong> in hospitals as well as{" "}
            <strong>faculty roles in teaching institutions.</strong> They can be
            appointed as{" "}
            <strong>
              faculty members, teachers, assistant professors, or lecturers in
              medical training institutions.
            </strong>
            The accreditation of institutes and the selection of residents are
            conducted strictly in accordance with the{" "}
            <strong>guidelines and regulations set by NBEMS</strong>, which are
            updated periodically.
          </p>
        </section>

        {/* Registration Form */}
        <section className="registration d-flex justify-content-center">
          {/* Image Section */}
          <div className="promo-image">
            <img
              src={PlasticSurgeryImg}
              alt="DrNB Plastic Surgery Program"
              title="DrNB Plastic Surgery Program Details"
              loading="lazy"
            />
          </div>
          <div className="admission-form-container mt-4">
            <h4 className="sec-title">
              Enroll in DrNB Plastic Surgery Program
            </h4>
            {status && <p className="status-message">{status}</p>}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Phone</label>
                <input
                  type="tel"
                  name="phone"
                  placeholder="Your Number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Subject</label>
                <input
                  type="text"
                  name="subject"
                  placeholder="Your Subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Message</label>
                <textarea
                  name="message"
                  placeholder="Your Message Here"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>

              <button type="submit" className="submit-btn">
                Submit
              </button>
            </form>
          </div>
        </section>

        {/* Neurosurgery Team */}
        <section className="team">
          <h2 className="sec-title">Our Plastic Surgery Team</h2>
          <div className="row row-cols-1 row-cols-md-4 g-4 ">
            {filteredDoctors.length > 0
              ? filteredDoctors.map((doctor, index) => (
                  <div className="col-lg-2 col-md-4" key={index}>
                    <a
                      className="doc-name"
                      href={`/Doctors/${doctor.name
                        .replace(/\s+/g, "")
                        .toLowerCase()}`}
                    >
                      <div className="card h-100 doctor_img my-doc">
                        <img
                          src={doctor.image}
                          alt={`${doctor.name} Img`}
                          title={doctor.name}
                        />
                        <div className="drnb-doc-text mt-2">
                          <h3>{doctor.name}</h3>
                          <p>{doctor.qualification}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                ))
              : ""}
          </div>
        </section>
      </div>
    </>
  );
}
export default DrNBPlasticSurgery;
