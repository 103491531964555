import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const SEO = ({ title, description, keywords }) => {
  const location = useLocation(); 
  const currentUrl = `https://ashwinihospitals.in${location.pathname}`; 
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={currentUrl} /> 
      <meta name="robots" content="index, follow" />
    </Helmet>
  );
};

export default SEO;
